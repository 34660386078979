import { Button, IconButton } from "@mui/material";

import React, { useState, useEffect } from 'react';

//IMPORT BASEURL
import baseURL from '../../api/api';

//ICON IMPORTS
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

//IMAGE IMPORTS
import defaultImage from '../../assets/images/defaultImage.png';


import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import Modal from "@mui/material/Modal";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";


const MainCarouselTable = () => {
  const [openMainCarouselAddModal, setOpenMainCarouselAddModal] = useState(false);
  const handleOpenMainCarouselAddModal = () => {
    clearInputs();
    setOpenMainCarouselAddModal(true);
  }
  const handleCloseMainCarouselAddModal = () =>
    setOpenMainCarouselAddModal(false);

  //DELETE MODAL DATA
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleDeleteDialogOpen = (id) => {
    setOpenDeleteDialog(true);
    setImgID(id);
  };
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };



  //GET MAIN SLIDER DATA
  const [mainSlider, setMainSlider] = useState([]);
  useEffect(() => getMainSlider(), []);

  const getMainSlider = async () => {
    const response = await baseURL.get('/institute/home/mainSlider/findAll');
    setMainSlider(response.data)
  }

  const renderedMainSlider = mainSlider.map(({ id, fileName, image }, index) => {
    return (
      <tr key={id} >
        <td className="tableCellData">{index + 1}.</td>
        <td className="imagePreviewContainer ">
          <div className="imagePreviewBox">
            <img src={image} className="landScapeImgPreview" />
          </div>
        </td>
        <td className="tableCellData">{fileName}</td>
        <td>
          <IconButton color="error">
            <DeleteIcon
              fontSize="medium"
              onClick={() => handleDeleteDialogOpen(id)}
            />
          </IconButton>
        </td>
      </tr>
    )
  })




  //INITIAL CREATE STATE
  const [image, setImage] = useState(defaultImage);
  const [name, setName] = useState('');
  const [file, setFile] = useState();


  const onImageChange = (e) => {
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]))
  };

  const onFileNameChange = (e) => {
    setName(e.target.value);
  };

  const mediaSubmit = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append('file', file);
    data.append('name', name);
    baseURL.post('/institute/home/mainSlider/save', data).then(response => {
      getMainSlider().then(() => {
        setOpenMainCarouselAddModal(false);
      })
    }).catch((err) => console.log(err, 'err err '))
  }

  const clearInputs = () => {
    setImage(defaultImage);
    setName('');
    setFile();
  }



  //DELETE IMAGE
  const [imgID, setImgID] = useState();
  const deleteImage = async (id) => {
    await baseURL.put(`/institute/home/mainSlider/deleteById/${id}`).then(res => {
      getMainSlider().then(() => setOpenDeleteDialog(false))
    });
  }



  return (
    <div>
      <div className="tabPanelHeader">
        <h3>Main Slider</h3>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenMainCarouselAddModal}
          className="addButton"
        >
          Add
        </Button>
      </div>
      <div>
        <table>
          <thead className="introCarouselTableHead">
            <th>SN</th>
            <th>Image Preview</th>
            <th>Image Name</th>
            <th>Delete</th>
          </thead>
          <tbody>
            {renderedMainSlider}
          </tbody>
        </table>
        <Modal
          open={openMainCarouselAddModal}
          onClose={handleCloseMainCarouselAddModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="updateModal"
        >
          <div className="addNewTeamModalPopUp">
            <div className="modalHeader">
              <h3>Add New Image</h3>
            </div>
            <div className="modalContainer">
              <FormControl className="modalContent" component="form" onSubmit={(e) => mediaSubmit(e)}>
                <div className="updateImagePreview">
                  <img src={image} />
                </div>
                <div className="textFieldContainer">
                  <TextField
                    type="file"
                    onChange={onImageChange}
                    variant="standard"
                    className="textField"
                    required
                  />

                  <TextField
                    label="Image Name"
                    onChange={onFileNameChange}
                    value={name}
                    variant="standard"
                    className="textField"
                    required
                  />
                </div>
                <div className="submitContainer">
                  <Button variant="contained" color="error" onClick={clearInputs}>
                    CLEAR
                  </Button>
                  <Button variant="contained" type="submit">SUBMIT</Button>
                </div>
              </FormControl>
            </div>
          </div>
        </Modal>
        <Dialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure want to delete the selected data?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleDeleteDialogClose}
              variant="contained"
              color="primary"
            >
              NO
            </Button>
            <Button
              onClick={() => { deleteImage(imgID) }}
              variant="contained"
              color="error"
              autoFocus
            >
              YES
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div >
  );
};

export default MainCarouselTable;


import { Button, Grid } from "@mui/material";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";



//IMPORT BASEURL
import baseURL from '../../api/api';




const Accreditations = () => {
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                },
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    infinite: true,
                    autoplay: true,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                },
            },
        ],
    };

    //GET ACCREDITATIONS DATA
    const [accreditations, setAccreditations] = useState([]);
    useEffect(() => getAccreditations(), []);
    const getAccreditations = async () => {
        const response = await baseURL.get('/institute/home/accreditation/findAll');
        setAccreditations(response.data.reverse())
    }

    const renderedAccreditations = accreditations.map(({ id, fileName, image }, index) => {
        return (
            <div>
                <img src={image} className="accreditationsImage" style={{ paddingLeft: '10px', paddingRight: '10px', height: '50px' }} />
            </div>
        )
    })




    return (
        <Grid container direction={{ xs: "row" }} className="mainAccreditations" >
            <Grid xs={12} item className="recommendationsTitle" >
                <h5 >
                    OUR ACCREDITATIONS
                </h5>
            </Grid>
            <Grid xs={12} item >
                <Slider {...settings} >
                    {renderedAccreditations}
                </Slider>
            </Grid>
        </Grid>
    )
}

export default Accreditations;





import { Button, Grid } from "@mui/material";
import girl from "../../assets/images/girl.png";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import serviceCoverGirl from '../../assets/images/serviceCoverGirl.png';



import baseURL from '../../api/api';
import { darken } from "@mui/lab/node_modules/@mui/system";

const OurServices = () => {

  useEffect(() => {
    getService();
  }, [])

  const [service, setService] = useState([]);
  const getService = async () => {
    const { data: responseData = {} } = await baseURL.get('/institute/ourService/findAll');
    let modifiedData = [];
    responseData.map((data) => {
      let { mainHeading } = data;
      mainHeading = mainHeading.trim().toLowerCase();
      if (mainHeading === "ielts") {
        modifiedData.splice(0, 0, data);
      } else if (mainHeading === "pte") {
        let [{ mainHeading: mh }] = modifiedData;
        mh = mh.trim().toLowerCase();
        if (mh === "ielts") {
          modifiedData.splice(1, 0, data);
        } else {
          modifiedData.splice(0, 0, data);
        }
      } else {
        modifiedData.push(data);
      }
    });
    setService(modifiedData);
  }

  // const renderedService = service.slice(0, 3).map((data) => {
  //   return (
  //     <div className="ourServiceCard" key={data.id} style={{border:'2px dotted red'}}>
  //       <div className="ourServiceImageContainer">
  //         <img src={data.image} style={{width:'100%'}} />
  //       </div>
  //       <div className="ourServiceHeader" style={{ maxHeight: '100px', marginTop: '8px',marginBottom: '8px' }}>
  //         <h3 className="para-clamping" alt={data.mainHeading}>
  //           {/* {data.mainHeading.substring(0, 90)}
  //           {data.mainHeading.length >= 90 ? '...' : null} */}
  //           {data.mainHeading}
  //         </h3>
  //       </div>
  //       <div className="ourServiceContent">
  //         <p className="threePara">
  //           {data.ourServiceContentHeadings[0].ourServiceContents[0].text}
  //           {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue elit in odio porta, eget efficitur ipsum suscipit. Pellentesque a euismod sem. Nulla id lorem tellus. Ut quis pulvinar velit. Proin eu congue lectus. Ut in pellentesque turpis. Sed ut nisi sed leo tristique ultricies id non augue. Fusce porta, quam ac tincidunt sollicitudin, purus dolor lobortis metus, eu vulputate lectus sem vitae risus. Fusce bibendum metus sed lobortis aliquet. Sed commodo sagittis erat. Vivamus at nisl et erat condimentum aliquet. Integer non semper dui. Curabitur varius eleifend nibh, sit amet commodo ligula molestie et. Praesent at aliquam metus. In egestas, lectus vehicula rhoncus tristique, tellus dolor elementum felis, condimentum tristique nibh purus non nisi.  */}
  //         </p>
  //       </div>
  //       <div className="serviceSeeMore">
  //         <Link to={`/service/${data.id}`}>
  //           Read More
  //         </Link>
  //       </div>
  //     </div >
  //   )
  // })


  const renderedService = service.slice(0, 2).map((data, index, arr) => {
    // console.log(data, 'DATA DATA DATA')
    // if (data.mainHeading.includes("IELTS") || data.mainHeading.includes("PTE")) {
    return (
      <div className="ourServiceCard">
        <div className="ourServiceImageContainer">
          <img src={data.image} style={{ width: '100%' }} />
        </div>
        <div className="onePara">
          <p className="serviceHeading">{data.mainHeading.toUpperCase()}</p>
        </div>
        <div className="ourServiceDescContainer">
          <p className="threePara">{data.ourServiceContentHeadings[0].ourServiceContents[0].text}</p>
        </div>
        <div className="ourServiceButtonContainer">
          <Link to={`/service/${data.id}`} className="getMoreDetailsBtn">
            Read More
          </Link>
        </div>
      </div>
    )
    // }
  })

  // const renderedService1= service.filter()





  return (
    <div className="ourServices">
      <div className="ourServiceHeading">
        <h5 style={{ paddingBottom: '16px' }}>OUR SERVICE</h5>
      </div>
      <div className="ourServiceContainer">
        {renderedService}
        <div className="viewMoreServiceCard">
          <div className="viewMoreServiceContent">
            <Link to="/ourservices" className="viewMoreLink">
              <div className="viewMoreContents">
                <div className="viewMoreText" style={{ width: '100%' }}>
                  <h3>
                    SEE <br></br> MORE <br></br> SERVICES <br></br><AddIcon className="addIcon" />
                  </h3>
                </div>
                {/* <div className="viewMoreIcon" style={{ width: '100%', border: '2px solid red' }} ><AddIcon className="addIcon" /> </div> */}
              </div>
            </Link>
          </div>
          <div className="viewMoreServiceImage">
            <img src={serviceCoverGirl} />
          </div>
        </div>
      </div >
    </div >
  );
};

export default OurServices;

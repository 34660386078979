import { Grid } from "@mui/material";
import { useState, useEffect } from 'react';


import {
    BrowserRouter as Router,
    useParams
} from "react-router-dom";

//IMPORT BASEURL
import baseURL from '../../../api/api';



const News = () => {
    let { id } = useParams();
    const [heading, setHeading] = useState();
    const [date, setDate] = useState();
    const [image, setImage] = useState();
    const [inputList, setInputList] = useState([{ text: "" }]);


    useEffect(() => getUpdateNews(id), [])

    const getUpdateNews = async (id) => {
        await baseURL.get(`/institute/home/news/findById/${id}`).then((res) => {
            setHeading(res.data.newsContentHeadings[0].heading);
            setDate(res.data.date);
            setImage(res.data.image);
            const updateNewsContents = res.data.newsContentHeadings[0].newsContents.map(({ text }) => {
                return { text }
            });
            setInputList(updateNewsContents)
        })
    }

    const theDate = new Date(date);
    let theMonth = theDate.toLocaleString('en-us', { month: 'long' });
    let theWeekDay = theDate.toLocaleDateString('en-US', { weekday: 'long' })
    let theDay = theDate.getDate();
    let theYear = theDate.getFullYear();

    const renderedNews = inputList.map(({ text }) => {
        return (
            <>
                <p style={{ marginTop: '16px', textAlign: 'justify' }}>{text} <br /> </p>
            </>
        )
    })


    return (
        <Grid container className="newsPage">
            <Grid container item className="newsHeadingContainer">
                <h1>{heading} </h1>
            </Grid>
            <Grid container item >
                <img src={image} style={{ width: '100%' }} />
            </Grid>
            <Grid container item className="newsMetaData">
                <span>
                    <p>
                        {theWeekDay}, {theMonth} {theDay}, {theYear}
                    </p>
                </span>
            </Grid>
            <Grid container item className="newsParaContainer">
                {renderedNews}
            </Grid>

        </Grid>
    )
}

export default News;
import React, { useState, useEffect } from "react";


import TopCarousel from "./TopCarousel";
import IntroContainer from "./IntroContainer";
import RecommendedPlace from "./RecommendedPlace";
import WhyChooseUs from "./WhyChooseUs";
import NewsAccreditations from "./NewsAccreditations";
import OurServices from "./OurServices";
import Accreditations from './Accreditations';
import TestimonyAndEvents from "./TestimonyAndEvents";
import Testimony from './Testimony';
import GetInTouch from "./GetInTouch";
import MeetOurTeam from "../common/innerComponents/MeetOurTeam";

const Landing = () => {
  return (
    <>
      <TopCarousel />
      <IntroContainer />
      <WhyChooseUs />
      <RecommendedPlace />
      <OurServices />
      <Accreditations />
      <MeetOurTeam />
      <TestimonyAndEvents />
      {/* <NewsAccreditations /> */}
      <GetInTouch />
      <Testimony />
    </>
  );
};

export default Landing;

import { Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";

//IMAGE IMPORTS
import defaultImage from "../../assets/images/defaultImage.png";

//IMPORT BASEURL
import baseURL from '../../api/api';

//ICON IMPORTS
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

//MODAL IMPORTS
import Modal from "@mui/material/Modal";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const TestimonialTable = () => {

  //ADD MODAL
  const [openAddModal, setOpenAddModal] =
    React.useState(false);
  const handleOpenAddModal = () => {
    clearStates();
    setOpenAddModal(true);
  }
  const handleCloseAddModal = () =>
    setOpenAddModal(false);

  //DELETE DIALOG 
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleDeleteDialogOpen = (id) => {
    setTestID(id);
    setOpenDeleteDialog(true);
  };
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };


  //EDIT MODAL
  const [openEditModal, setOpenEditModal] =
    React.useState(false);
  const handleOpenEditModal = (id) => {
    setTestID(id);
    getUpdateTestimony(id).then(() => {
      setOpenEditModal(true);
    })
  }
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  }


  //INITIAL CREATE STATE
  const [image, setImage] = useState(defaultImage);
  const [file, setFile] = useState();
  const [name, setName] = useState();
  const [country, setCountry] = useState();
  const [content, setContent] = useState();


  const onImageChange = (e) => {
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]))
  };

  const onInputChange = (e) => {
    if (e.target.id === 'testimonyPerson') {
      setName(e.target.value);
    }
    if (e.target.id === 'testimonyCountry') {
      setCountry(e.target.value);
    }
    if (e.target.id === 'testimonyTestimony') {
      setContent(e.target.value);
    }
  }

  const mediaSubmit = async (e) => {
    e.preventDefault();

    const testimonial = {
      name,
      country,
      content
    }

    let data = new FormData();
    data.append('file', file);
    data.append('testimonial', JSON.stringify(testimonial));
    await baseURL.post('/institute/home/testimonial/save', data).then(response => {
      getTestimony().then(() => {
        setOpenAddModal(false);
      })
    }).catch((err) => console.log(err, 'err err '))
  }


  //GET TESTIMONIALS DATA
  const [myTestimony, setMyTestimony] = useState([]);
  useEffect(() => getTestimony(), []);

  const getTestimony = async () => {
    const response = await baseURL.get('/institute/home/testimonial/findAll');
    setMyTestimony(response.data);
  }

  const renderedTestimony = myTestimony.map(({ id, name, image, country, content }, index) => {
    return (
      <tr>
        <td className="tableCellData">{index + 1}.</td>
        <td className="imagePreviewContainer">
          <div className="imagePreviewBox">
            <img src={image} className="portImagePreview" />
          </div>
        </td>
        <td className="tableCellData">{name}</td>
        <td className="tableCellData">{country}</td>
        <td className="tableCellData" title={content}>
          <span className="singleLineClamp" alt={content}>
            {content}
          </span>
        </td>
        <td>
          <IconButton
            color="secondary"
            onClick={() => handleOpenEditModal(id)}
          >
            <EditIcon fontSize="medium" />
          </IconButton>
        </td>
        <td>
          <IconButton color="error">
            <DeleteIcon
              fontSize="medium"
              onClick={() => handleDeleteDialogOpen(id)}
            />
          </IconButton>
        </td>
      </tr>
    )
  })

  //DELETE TESTIMONY ID
  const [testID, setTestID] = useState('');
  const deleteImage = async (testID) => {
    await baseURL.delete(`/institute/home/testimonial/delete/${testID}`).then(res => {
      getTestimony().then(() => {
        setOpenDeleteDialog(false);
      })
    });
  }

  //GET UPDATE TESTIMONY 
  const getUpdateTestimony = async (id) => {
    await baseURL.get(`/institute/home/testimonial/findById/${id}`).then((res) => {
      setImage(res.data.image);
      setName(res.data.name);
      setCountry(res.data.country);
      setContent(res.data.content);
    }).catch((err) => {
      console.log(err, 'my error')
    });
  }

  //UPDATE TESTIMONY

  const mediaUpdate = (e) => {
    e.preventDefault();

    const testimonial = {
      name,
      country,
      content
    }


    let data = new FormData();
    data.append('file', file);
    data.append('testimonial', JSON.stringify(testimonial));
    baseURL.put(`/institute/home/testimonial/update/${testID}`, data).then(async res => {
      await getTestimony().then(() => {
        setOpenEditModal(false);
      })
    })
  }


  //CLEAR STATES
  const clearStates = () => {
    setImage(defaultImage);
    setFile('');
    setName('');
    setCountry('');
    setContent('');
  }






  return (
    <>
      <div className="tabPanelHeader">
        <h3>Testimonial Table</h3>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
          className="addButton"
        >
          Add
        </Button>
      </div>
      <div>
        <table>
          <thead className="serviceTableHead">
            <th>SN</th>
            <th>Image Preview</th>
            <th>Person</th>
            <th>Country</th>
            <th>Testimony</th>
            <th>Edit</th>
            <th>Delete</th>
          </thead>
          <tbody>
            {renderedTestimony}
          </tbody>
        </table>

        <Modal
          open={openEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="updateModal"
        >
          <div className="addNewTeamModalPopUp">
            <div className="modalHeader">
              <h3>Update Testimony</h3>
            </div>
            <div className="modalContainer">
              <FormControl className="modalContent" component="form" onSubmit={(e) => mediaUpdate(e)}>
                <div className="updateImagePreview">
                  <img src={image} />
                </div>
                <div className="textFieldContainer">
                  <TextField
                    type="file"
                    onChange={onImageChange}
                    variant="standard"
                    className="textField"
                  />

                  <TextField
                    label="Enter the Name"
                    id="testimonyPerson"
                    onChange={onInputChange}
                    value={name}
                    variant="standard"
                    className="textField"
                    required
                  />
                  <TextField
                    label="Enter the Country"
                    id="testimonyCountry"
                    onChange={onInputChange}
                    value={country}
                    variant="standard"
                    className="textField"
                    required
                  />
                  <TextField
                    id="testimonyTestimony"
                    label="Enter the Testimony"
                    onChange={onInputChange}
                    value={content}
                    multiline
                    rows={4}
                    required
                  />
                </div>
                <div className="submitContainer">
                  <Button variant="contained" color="error" onClick={clearStates}>
                    CLEAR
                  </Button>
                  <Button variant="contained" type="submit">UPDATE</Button>
                </div>
              </FormControl>
            </div>
          </div>
        </Modal>

        <Modal
          open={openAddModal}
          onClose={handleCloseAddModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="updateModal"
        >
          <div className="addNewTeamModalPopUp">
            <div className="modalHeader">
              <h3>Add Testimony</h3>
            </div>
            <div className="modalContainer">
              <FormControl className="modalContent" component="form" onSubmit={(e) => mediaSubmit(e)}>
                <div className="updateImagePreview">
                  <img src={image} />
                </div>
                <div className="textFieldContainer">
                  <TextField
                    type="file"
                    onChange={onImageChange}
                    variant="standard"
                    className="textField"
                  />

                  <TextField
                    label="Enter the Name"
                    id="testimonyPerson"
                    onChange={onInputChange}
                    value={name}
                    variant="standard"
                    className="textField"
                    required
                  />
                  <TextField
                    label="Enter the Country"
                    id="testimonyCountry"
                    onChange={onInputChange}
                    value={country}
                    variant="standard"
                    className="textField"
                    required
                  />
                  <TextField
                    id="testimonyTestimony"
                    label="Enter the Testimony"
                    onChange={onInputChange}
                    value={content}
                    multiline
                    rows={4}
                    required
                  />
                </div>
                <div className="submitContainer">
                  <Button variant="contained" color="error" onClick={clearStates}>
                    CLEAR
                  </Button>
                  <Button variant="contained" type="submit" >SUBMIT</Button>
                </div>
              </FormControl>
            </div>
          </div>
        </Modal>

        <Dialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure want to delete the selected data?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleDeleteDialogClose}
              variant="contained"
              color="primary"
            >
              NO
            </Button>
            <Button
              onClick={() => deleteImage(testID)}
              variant="contained"
              color="error"
              autoFocus
            >
              YES
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default TestimonialTable;

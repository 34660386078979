
import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';


//IMPORT BASEURL
import baseURL from '../../api/api';




const Testimony = () => {

    //GET TESTIMONIALS DATA
    const [myTestimony, setMyTestimony] = useState([]);
    useEffect(() => getTestimony(), []);

    const getTestimony = async () => {
        const response = await baseURL.get('/institute/home/testimonial/findAll');
        setMyTestimony(response.data);
    }

    const renderedTestimony = myTestimony.map(({ id, name, image, country, content }, index) => {
        return (
            <div style={{ border: '2px dotted red' }} className="testimonyCard">
                <img src={image} />

                <div className="testimonyImageHolder">
                </div>
                <div className="testimonyContentHolder">
                    <div className="testimonyContainer1">
                        <div>
                            <FormatQuoteIcon style={{ transform: 'rotate(180deg)' }} />
                            <span className="testimonyPara"> {content}</span>
                        </div>
                        <div className="testimonyBioContainer">
                            <span className="testimonyPara"> -{name.toUpperCase()}</span>
                            <span className="testimonyPara"> {country}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    })


    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                },
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    infinite: true,
                    autoplay: true,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                },
            },
        ],
    };


    return (
        <div className="mainTestimony">
            <div className="mainTestimonyTitleHolder">
                <h5>STUDENT TESTIMONIALS</h5>
            </div>
            <div className="testimonyContainer">
                <Slider {...settings}>
                    {renderedTestimony}
                </Slider>
            </div>
        </div>
    );
};

export default Testimony;
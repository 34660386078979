import { Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import intropic from '../../assets/images/intropic.jpeg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


//IMPORT BASEURL
import baseURL from '../../api/api';

const IntroContainer = () => {

  const [introSlider, setIntroSlider] = useState([]);
  useEffect(() => getIntroSlider(), []);

  const getIntroSlider = async () => {
    const response = await baseURL.get('/institute/home/flowSlider/findAll');
    setIntroSlider(response.data)
  }


  const renderedIntroSlider = introSlider.map(({ id, fileName, image }) => {
    return (
      <img src={image} key={id} />
    )
  });


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    adaptiveHeight: true,
  };

  return (
    <Grid container className="introContainer" direction={{xs:'column', sm:'row'}} >
      <Grid container item xs sm lg justifyContent={{xs:'flex-start'}} alignItems={{xs:'flex-start'}} className="introCarousel">
        {/* <Slider {...settings}>
          {renderedIntroSlider}
        </Slider> */}
        <img src={intropic} style={{ width: '100%',height:'auto' }} />
      </Grid>
      <Grid
        container
        item
        xs
        sm
        lg
        className="introNAVC"
        direction={{xs:'column'}}
        justifyContent={{xs:'row'}}
      >
        <Grid container item className="introText">
          <h5 style={{ textAlign: "center", color: '#9b1d21' }} >
            WELCOME TO NORTHERN STAR EDUCATION CONSULTANCY
          </h5>
        </Grid>
        <Grid container item className="introDesc" direction={{xs:'column'}} justifyContent="space-between">
          <Grid container item>
            <p style={{ textAlign: "justify" }}>
              We have industry standard experts to handle your queries and have
              your problems solved with their optimized solutions specially
              designed for your needs.We help you choose the best courses and
              help you grow to become an international student who can grow to
              become the part of <span style={{ color: '#9b1d21', fontWeight: '600' }}>a GLOBAL MARKET.</span>
            </p>
          </Grid>
          <Grid container item className="introReadMore">
            <Link to={`/aboutus`}>
              <button className="readMoreButton">
                Read More
              </button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntroContainer;

import baseURL from '../../../api/api';
import React, { useState, useEffect } from "react";
import { Grid } from '@mui/material';


import {
    BrowserRouter as Router,
    useParams
} from "react-router-dom";




const CountryDetails = () => {
    let { name } = useParams();

    const [country, setCountry] = useState([]);
    useEffect( () =>  getCountry(), []);



    const getCountry = async () => {
        await baseURL.get(`/institute/country/findByName/${name}`).then((res) => {
            setCountry([res.data]);
        })
    }

    const renderedCountry = country.map((data) => {

        const renderedPara = data.contentHeadings[0].contents.map(({ contentType, text }) => {
            if (contentType == 'title') {
                return <h3 style={{ marginTop: '16px', textAlign: 'justify' }}>{text} <br /> </h3>
            }
            if (contentType === 'para') {
                return <p style={{ textAlign: 'justify' }}>{text} <br /> </p>
            }
            if (contentType === 'list') {
                return <li style={{ marginLeft: '16px' }}>{text}</li>
            }
            return null
        })


        return (
            <Grid container item direction="column" key={data.id}>
                <Grid container item direction="column">
                    <h1>{data.countryName}</h1>
                </Grid>
                <Grid container item direction="column">
                    <img src={data.image} style={{ width: '100%' }} />
                </Grid>
                <Grid container item style={{ display: 'flex', flexDirection: 'column' }}>
                    {renderedPara}
                </Grid>
            </Grid>
        )
    })

    return (
        <Grid container item className="serviceDetail">
            {renderedCountry}
        </Grid>

    )
}

export default CountryDetails;

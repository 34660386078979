import { Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from 'react';

//IMPORT BASEURL
import baseURL from '../../api/api';

//IMAGE IMPORTS
import defaultImage from '../../assets/images/defaultImage.png';

//ICON IMPORTS
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

//MODAL IMPORTS
import Modal from "@mui/material/Modal";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const IntroCarouselTable = () => {


  //ADD MODAL
  const [openIntroCarouselAddModal, setOpenIntroCarouselAddModal] =
    React.useState(false);
  const handleOpenIntroCarouselAddModal = () => {
    clearStates();
    setOpenIntroCarouselAddModal(true);
  }
  const handleCloseIntroCarouselAddModal = () =>
    setOpenIntroCarouselAddModal(false);

  //delete modal data
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleDeleteDialogOpen = (id) => {
    setOpenDeleteDialog(true);
    setImgID(id);

  };
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };


  //GET INTRO SLIDER DATA
  const [introSlider, setIntroSlider] = useState([]);
  useEffect( () =>  getIntroCarousel(), []);

  const getIntroCarousel = async () => {
    const response = await baseURL.get('/institute/home/flowSlider/findAll');
    setIntroSlider(response.data)
  }

  const renderedIntroSlider = introSlider.map(({ id, fileName, image }, index) => {
    return (
      <tr key={id} >
        <td className="tableCellData">{index + 1}.</td>
        <td className="imagePreviewContainer">
          <div className="imagePreviewBox">
            <img src={image} className="portImagePreview" />
          </div>
        </td>
        <td className="tableCellData">{fileName}</td>
        <td>
          <IconButton color="error">
            <DeleteIcon
              fontSize="medium"
              onClick={() => handleDeleteDialogOpen(id)}
            />
          </IconButton>
        </td>
      </tr>

    )
  })


  //INITIAL CREATE STATE
  const [image, setImage] = useState(defaultImage);
  const [name, setName] = useState('');
  const [file, setFile] = useState();

  const onImageChange = (e) => {
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]))

  };

  const onFileNameChange = (e) => {
    setName(e.target.value);
  };

  const mediaSubmit = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append('file', file);
    data.append('name', name);


    baseURL.post('/institute/home/flowSlider/save', data).then(response => {
      getIntroCarousel();
      setOpenIntroCarouselAddModal(false);
    }).catch((err) => console.log(err, 'err err '))
  }



  //DELETE IMAGE
  const [imgID, setImgID] = useState();
  const deleteImage = (id) => {
    baseURL.put(`/institute/home/flowSlider/deleteById/${id}`).then(res => {
      getIntroCarousel().then(() => setOpenDeleteDialog(false))
    });
  }


  //CLEAR STATES
  const clearStates = () => {
    setImage(defaultImage);
    setName('');
    setFile();
  }






  return (
    <>
      <div className="tabPanelHeader">
        <h3>Intro Slider</h3>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenIntroCarouselAddModal}
          className="addButton"
        >
          Add
        </Button>
      </div>
      <div>
        <table>
          <thead className="introCarouselTableHead">
            <th>SN</th>
            <th>Image Preview</th>
            <th>Image Name</th>
            <th>Delete</th>
          </thead>
          <tbody>
            {renderedIntroSlider}
          </tbody>
        </table>

        <Modal
          open={openIntroCarouselAddModal}
          onClose={handleCloseIntroCarouselAddModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="updateModal"
        >
          <div className="addNewTeamModalPopUp">
            <div className="modalHeader">
              <h3>Add Image</h3>
            </div>
            <div className="modalContainer">
              <FormControl className="modalContent" component="form" onSubmit={(e)=>mediaSubmit(e)}>
                <div className="updateImagePreview">
                  <img src={image} />
                </div>
                <div className="textFieldContainer">
                  <TextField
                    type="file"
                    onChange={onImageChange}
                    variant="standard"
                    className="textField"
                    required
                  />

                  <TextField
                    label="Image Name"
                    onChange={onFileNameChange}
                    value={name}
                    variant="standard"
                    className="textField"
                    required
                  />
                </div>
                <div className="submitContainer">
                  <Button variant="contained" color="error" onClick={clearStates}>
                    CLEAR
                  </Button>
                  <Button variant="contained" type="submit">SUBMIT</Button>
                </div>
              </FormControl>
            </div>
          </div>
        </Modal>

        <Dialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure want to delete the selected data?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleDeleteDialogClose}
              variant="contained"
              color="primary"
            >
              NO
            </Button>
            <Button
              // onClick={handleDeleteDialogClose}
              onClick={() => { deleteImage(imgID) }}
              variant="contained"
              color="error"
              autoFocus
            >
              YES
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default IntroCarouselTable;

import { Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";

//IMAGE IMPORTS
import defaultImage from '../../assets/images/defaultImage.png';

//IMPORT BASEURL
import baseURL from '../../api/api';

//ICON IMPORTS
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from '@mui/icons-material/Cancel';

//MODAL IMPORTS
import Modal from "@mui/material/Modal";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";


const CountryTable = () => {

    //ADD MODAL
    const [openAddModal, setOpenAddModal] =
        React.useState(false);
    const handleOpenAddModal = () => {
        clearStates();
        setOpenAddModal(true);
    }
    const handleCloseAddModal = () =>
        setOpenAddModal(false);


    //EDIT MODAL
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const handleOpenEditModal = (id) => {
        setCountryID(id);
        setOpenEditModal(true);
        getUpdateCountry(id);
    }
    const handleCloseEditModal = () => {
        clearStates();
        setOpenEditModal(false);

    }


    //DELETE DIALOG 
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const handleDeleteDialogOpen = (id) => {
        setCountryID(id);
        setOpenDeleteDialog(true);
    };
    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };


    const [image, setImage] = useState(defaultImage);
    const [file, setFile] = useState('');
    const [image1, setImage1] = useState(defaultImage);
    const [file1, setFile1] = useState('');
    const [countryName, setCountryName] = useState('');
    const [inputList, setInputList] = useState([{ contentType: 'para', text: "" }]);

    // GET COUNTRY DATA
    const [mycountry, setMyCountry] = useState([]);
    const [countryID, setCountryID] = useState('');


    const handleChange = (event) => {
        setCountryName(event.target.value);
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = (e) => {
        if (e.target.id === 'addPara') {
            setInputList([...inputList, { contentType: 'para', text: "" }]);
        }

        if (e.target.id === 'addTitle') {
            setInputList([...inputList, { contentType: 'title', text: "" }]);
        }
        if (e.target.id === 'addList') {
            setInputList([...inputList, { contentType: 'list', text: "" }]);
        }
    };

    const onImageChange = (e) => {
        if (e.target.id === "countryImage") {
            console.log(e.target.files[0], 'NOTIFY COUNTRY');
            setFile(e.target.files[0]);
            setImage(URL.createObjectURL(e.target.files[0]))
        }
        if (e.target.id === "countryLogo") {
            console.log(e.target.files[0], 'NOTIFY LOGO');
            setFile1(e.target.files[0]);
            setImage1(URL.createObjectURL(e.target.files[0]))
        }
    }

    // COUNTRY MEDIA SUBMIT
    const mediaSubmit = async (e) => {
        e.preventDefault();

        let country = {
            countryName: countryName,
            image: '',
            logo: '',
            contentHeadings: [
                {
                    heading: "Interesting Facts About Australia",
                    contents: inputList
                }
            ]
        }

        let data = new FormData();
        data.append('file', file);
        data.append('logo', file1);
        data.append('country', JSON.stringify(country));

        for (var pair of data.entries()) {
            console.log(pair[0] + ', ' + pair[1], 'MEDIA SUBMIT');
        }


        await baseURL.post('/institute/country/save', data).then(response => {
            getCountries().then(() => {
                setOpenAddModal(false);
            })
        }).catch((err) => {
            if (err.response.status === 400) {
                alert('Country Name already exists.')
                return;
            }
        }
        )
    }

    //GET COUNTRY  BY ID
    const getUpdateCountry = async (id) => {
        await baseURL.get(`/institute/country/findById/${id}`).then((res) => {
            setImage(res.data.image);
            setImage1(res.data.logo);
            setCountryName(res.data.countryName);

            const updateContents = res.data.contentHeadings[0].contents.map(({ text, contentType }) => {
                return { text, contentType };
            });
            setInputList(updateContents)
        })
    }

    //COUNTRY DELETE
    const deleteCountry = (id) => {
        baseURL.delete(`/institute/country/deleteById/${id}`).then(res => {
            getCountries().then(() => {
                setOpenDeleteDialog(false);
            })
        }).catch((err) => {
            if (err.response.status === 400) {
                setOpenDeleteDialog(false);
                alert(err.response.data)
                return;
            }
        }
        );
    }


    useEffect( () => getCountries(), []);
    const getCountries = async () => {
        const response = await baseURL.get('/institute/country/findAll');
        setMyCountry(response.data);
    }

    const renderedCountries = mycountry.map((data, index) => {
        return (
            <tr key={data.id}>
                <td className="tableCellData">{index + 1}.</td>
                <td className="tableCellData">{data.countryName}</td>
                <td className="imagePreviewContainer">
                    <div className="imagePreviewBox">
                        <img src={data.image} className="portImagePreview" />
                    </div>
                </td>
                <td className="imagePreviewContainer">
                    <div className="imagePreviewBox">
                        <img src={data.logo} className="portImagePreview" />
                    </div>
                </td>

                <td className="tableCellData" title={data.contentHeadings[0].contents[0].text}>
                    <span className="singleLineClamp">
                        {data.contentHeadings[0].contents[0].text}
                    </span>
                </td>
                <td>
                    <IconButton
                        color="secondary"
                        onClick={() => handleOpenEditModal(data.id)}
                    >
                        <EditIcon fontSize="medium" />
                    </IconButton>
                </td>
                <td>
                    <IconButton color="error">
                        <DeleteIcon
                            fontSize="medium"
                            onClick={() => handleDeleteDialogOpen(data.id)}
                        />
                    </IconButton>
                </td>
            </tr>
        )

    })


    // UPDATE COUNTRY
    const mediaUpdate = async () => {
        let country = {
            countryName: countryName,
            image: '',
            logo: '',
            contentHeadings: [
                {
                    heading: "Interesting Facts About Australia",
                    contents: inputList
                }
            ]
        }

        let data = new FormData();
        data.append('file', file);
        data.append('logo', file1);
        data.append('country', JSON.stringify(country));

        for (var pair of data.entries()) {
            console.log(pair[0] + ', ' + pair[1], 'MEDIA UPDATE');
        }

        await baseURL.put(`/institute/country/updateCountry/${countryID}`, data).then(response => {
            getCountries().then(() => {
                setOpenEditModal(false);
                clearStates();
            })
        }).catch((err) => console.log(err, 'err err '))

    }


    //CLEAR STATES
    const clearStates = () => {
        setImage(defaultImage);
        setImage1(defaultImage);
        setFile('');
        setFile1('');
        setCountryName('');
        setInputList([{ contentType: 'para', text: "" }])
    }


    return (
        <>
            <div className="tabPanelHeader">
                <h3>Country Table</h3>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    className="addButton"
                    onClick={() => handleOpenAddModal()}
                >
                    Add
                </Button>
            </div>
            <div>
                <table>
                    <thead className="serviceTableHead">
                        <th>SN</th>
                        <th>Country Name</th>
                        <th>Image</th>
                        <th>Logo</th>
                        <th>Contents</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </thead>
                    <tbody>
                        {renderedCountries}
                    </tbody>
                </table>


                <Modal
                    open={openAddModal}
                    onClose={handleCloseAddModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="updateModal"
                >
                    <div className="addNewTeamModalPopUp">
                        <div className="modalHeader">
                            <h3>Add Country Details</h3>
                        </div>
                        <div className="modalContainer">
                            <FormControl component="form" onSubmit={(e) => mediaSubmit(e)} className="modalContent">
                                <div className="updateImagePreview">
                                    <img src={image}
                                    />
                                </div>
                                <div className="textFieldContainer">
                                    <TextField
                                        type="file"
                                        label="Select Country Image"
                                        onChange={onImageChange}
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                        className="textField"
                                        id="countryImage"
                                        required
                                    />

                                    <div className="updateImagePreview">
                                        <img src={image1} />
                                    </div>

                                    <TextField
                                        type="file"
                                        label="Select Country Logo"
                                        onChange={onImageChange}
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                        className="textField"
                                        id="countryLogo"
                                        required

                                    />

                                    <TextField
                                        label="Country Name "
                                        id="countryName"
                                        onChange={handleChange}
                                        value={countryName}
                                        variant="standard"
                                        className="textField"
                                        required
                                    />

                                    {inputList.map((x, i) => {
                                        if (x.contentType === 'para') {
                                            return (
                                                <div className="box" key={i}>
                                                    <TextField
                                                        label="Country Paragraph"
                                                        name="text"
                                                        style={{ width: '100%' }}
                                                        value={x.text}
                                                        onChange={e => handleInputChange(e, i)}
                                                        multiline
                                                        rows={4}
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&

                                                            <IconButton color="error">
                                                                <CancelIcon
                                                                    fontSize="medium"
                                                                    onClick={() => handleRemoveClick(i)}
                                                                />
                                                            </IconButton>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        }
                                        if (x.contentType === 'title') {
                                            return (
                                                <div className="box" key={i}>
                                                    <TextField
                                                        label="Sub Title"
                                                        name='text'
                                                        style={{ width: '100%' }}
                                                        value={x.text}
                                                        onChange={e => handleInputChange(e, i)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <CancelIcon style={{ cursor: "pointer" }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        variant="standard"
                                                        className="textField"
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&

                                                            <IconButton color="error">
                                                                <CancelIcon
                                                                    fontSize="medium"
                                                                    onClick={() => handleRemoveClick(i)}
                                                                />
                                                            </IconButton>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        }
                                        if (x.contentType === 'list') {
                                            return (
                                                <div className="box" key={i}>
                                                    <TextField
                                                        label="List"
                                                        name='text'
                                                        style={{ width: '100%' }}
                                                        value={x.text}
                                                        onChange={e => handleInputChange(e, i)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <CancelIcon style={{ cursor: "pointer" }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        variant="standard"
                                                        className="textField"
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&

                                                            <IconButton color="error">
                                                                <CancelIcon
                                                                    fontSize="medium"
                                                                    onClick={() => handleRemoveClick(i)}
                                                                />
                                                            </IconButton>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                                <div className="submitContainer">
                                    <Button variant="contained" color="error" onClick={() => clearStates()}>
                                        CLEAR
                                    </Button>
                                    <Button variant="contained" type="submit">SUBMIT</Button>
                                </div>
                            </FormControl>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={openEditModal}
                    onClose={handleCloseEditModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="updateModal"
                >
                    <div className="addNewTeamModalPopUp">
                        <div className="modalHeader">
                            <h3>Update Country</h3>
                        </div>
                        <div className="modalContainer">
                            <FormControl className="modalContent">
                                <div className="updateImagePreview">
                                    <img src={image} />
                                </div>
                                <div className="textFieldContainer">
                                    <TextField
                                        type="file"
                                        onChange={onImageChange}
                                        variant="standard"
                                        className="textField"
                                        id="countryImage"
                                        label="Update Country Image"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <div className="updateImagePreview">
                                        <img src={image1} />
                                    </div>
                                    <TextField
                                        type="file"
                                        onChange={onImageChange}
                                        variant="standard"
                                        className="textField"
                                        label="Update Country Logo"
                                        InputLabelProps={{ shrink: true }}
                                        id="countryLogo"
                                    />

                                    <TextField
                                        label="Country Name "
                                        id="countryName"
                                        onChange={handleChange}
                                        value={countryName}
                                        variant="standard"
                                        className="textField"
                                    />
                                    {inputList.map((x, i) => {
                                        if (x.contentType === 'para') {
                                            return (
                                                <div className="box" key={i}>
                                                    <TextField
                                                        label="Country Paragraph"
                                                        name="text"
                                                        style={{ width: '100%' }}
                                                        value={x.text}
                                                        onChange={e => handleInputChange(e, i)}
                                                        multiline
                                                        rows={4}
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&
                                                            <>
                                                                <IconButton color="error">
                                                                    <CancelIcon
                                                                        fontSize="medium"
                                                                        onClick={() => handleRemoveClick(i)}
                                                                    />
                                                                </IconButton>
                                                            </>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div className="CMSkeys">
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );

                                        }
                                        if (x.contentType === 'title') {
                                            return (
                                                <div className="box">

                                                    <TextField
                                                        label="Country Secondary Title"
                                                        style={{ width: '100%' }}
                                                        onChange={e => handleInputChange(e, i)}
                                                        value={x.text}
                                                        name="text"
                                                        variant="standard"
                                                        className="textField"
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&
                                                            <IconButton color="error">
                                                                <CancelIcon
                                                                    fontSize="medium"
                                                                    onClick={() => handleRemoveClick(i)}
                                                                />
                                                            </IconButton>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div className="CMSkeys">
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );

                                        }
                                        if (x.contentType === 'list') {
                                            return (
                                                <div className="box">

                                                    <TextField
                                                        label="Country Secondary List"
                                                        style={{ width: '100%' }}
                                                        onChange={e => handleInputChange(e, i)}
                                                        name="text"
                                                        value={x.text}
                                                        variant="standard"
                                                        className="textField"
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&
                                                            <IconButton color="error">
                                                                <CancelIcon
                                                                    fontSize="medium"
                                                                    onClick={() => handleRemoveClick(i)}
                                                                />
                                                            </IconButton>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div className="CMSkeys">
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                                <div className="submitContainer">
                                    <Button variant="contained" color="error" onClick={() => clearStates()} >
                                        CLEAR
                                    </Button>
                                    <Button variant="contained" onClick={() => mediaUpdate()}>UPDATE</Button>
                                </div>
                            </FormControl>
                        </div>

                    </div>
                </Modal >

                <Dialog
                    open={openDeleteDialog}
                    onClose={handleDeleteDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Are you sure want to delete the selected data?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            onClick={handleDeleteDialogClose}
                            variant="contained"
                            color="primary"
                        >
                            NO
                        </Button>
                        <Button
                            onClick={() => { deleteCountry(countryID) }}
                            variant="contained"
                            color="error"
                            autoFocus
                        >
                            YES
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}


export default CountryTable;


// {
//     "countryName": "string",
//     "image": "string",
//     "logo": "string",
//     "contentHeadings": [
//       {
//         "heading": "string",
//         "contents": [
//           {
//             "contentType": "string",
//             "text": "string"
//           }
//         ],
//       }
//     ]
//   }
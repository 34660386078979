import { Button, FormControl, Grid, TextField } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PinDropIcon from "@mui/icons-material/PinDrop";
import React, { useState, useEffect } from 'react';

//IMPORT BASEURL
import baseURL from '../../api/api';

const sanitize = (str) => {
  return str.replace(/[^A-z0-9 @?.+-]/g, "");
};

const GetInTouch = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');


  const onInputChange = e => {

    if (e.target.id === 'theName') {
      setName(sanitize(e.target.value));
    }
    if (e.target.id === 'theEmail') {
      setEmail(sanitize(e.target.value));
    }
    if (e.target.id === 'theSubject') {
      setSubject(sanitize(e.target.value));
    }
    if (e.target.id === "theMessage") {
      setMessage(sanitize(e.target.value));
    }
  }

  const mediaSubmit = (e) => {
    e.preventDefault();
    let body = `Email:${email}\nName:${name}\nMessage:${message}`;
    const mailSender = {
      body,
      subject
    }
    baseURL.post('/api/mailService', mailSender).then(response => {
      console.log(response, 'BACKUP')
      clearStates();
    })
  }

  const clearStates = () => {
    setName('');
    setEmail('');
    setSubject('');
    setMessage('');
  }



  return (
    <Grid className="getInTouch" container direction={{ xs: "column" }}>
      <Grid
        container
        item
        xs="auto"
        justifyContent={{ xs: "center" }}
        className="getInTouchTitle"
      >
        <h5>GET IN TOUCH</h5>
      </Grid>
      <Grid
        container
        item
        xs="auto"
        columnSpacing={{ md: 3, lg: 1 }}
        direction={{ xs: "column", md: "row", lg: "row" }}
      >
        <Grid
          container
          item
          xs="auto"
          md={5}
          lg={6}
          direction={{ xs: "column" }}
          className="contactInfo"
        >
          <Grid item>
            <Grid container item direction={{ xs: "row" }}>
              <Grid
                container
                item
                xs={2}
                justifyContent={{ xs: "center" }}
                alignItems={{ xs: "center" }}
              >
                <PhoneIcon className="contactIcons" />
              </Grid>
              <Grid container item xs={10}>
                <Grid container item>
                  <p>Contact Number</p>
                </Grid>
                <Grid container item className="contactInfoMetaData">
                  <p><a href="tel:01-5551086" style={{ color: 'black' }}> 01-5551086</a></p>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction={{ xs: "row" }}>
              <Grid
                container
                item
                xs={2}
                justifyContent={{ xs: "center" }}
                alignItems={{ xs: "center" }}
              >
                <EmailIcon className="contactIcons" />
              </Grid>
              <Grid container item xs={10}>
                <Grid container item>
                  <p>Email for Information</p>
                </Grid>
                <Grid container item className="contactInfoMetaData">
                  <p><a href="mailto:info@northernstar.com.np" style={{ color: 'black' }}>info@northernstar.com.np</a></p>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction={{ xs: "row" }}>
              <Grid
                container
                item
                xs={2}
                justifyContent={{ xs: "center" }}
                alignItems={{ xs: "center" }}
              >
                <PinDropIcon className="contactIcons" />
              </Grid>
              <Grid container item xs={10}>
                <Grid container item>
                  <p>Office Location</p>
                </Grid>
                <Grid container item className="contactInfoMetaData">
                  <p>Jawalakhel, Lalitpur, Nepal</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ height: '300px', marginTop: '16px' }}>
            {/* <iframe src="https://maps.google.com/maps?q=27.66893682384618, 85.31758502669169&z=15&output=embed" width="100%" height="100%" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d883.3652245474126!2d85.3133284!3d27.6721464!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19fafce1a2e1%3A0x1cada3183ff375a6!2sNorthern%20Star%20Education%20Consultancy!5e0!3m2!1sen!2snp!4v1658594475427!5m2!1sen!2snp" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{ border: 0 }} ></iframe>
          </Grid>
        </Grid>
        <Grid container item xs="auto" md={7} lg={6}>
          <Grid container item xs={12} className="getInTouchFormHolder">
            <FormControl xs={12} component="form" onSubmit={(e) => mediaSubmit(e)} className="getInTouchForm">
              <TextField
                id="theName"
                label="Name"
                variant="outlined"
                value={name}
                className="getInTouchFormInput"
                onChange={(e) => onInputChange(e)}
              />
              <TextField
                id="theEmail"
                label="Email"
                variant="outlined"
                value={email}
                className="getInTouchFormInput"
                onChange={(e) => onInputChange(e)}
              />
              <TextField
                id="theSubject"
                label="Subject"
                variant="outlined"
                value={subject}
                className="getInTouchFormInput"
                onChange={(e) => onInputChange(e)}
              />
              <TextField
                id="theMessage"
                label="Message"
                multiline
                rows={5}
                className="getInTouchFormInput"
                value={message}
                onChange={(e) => onInputChange(e)}
              />
              <Button variant="outlined" type="submit" className="getInTouchFormSubmitButton readMoreButton" >
                Send Message
              </Button>



            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GetInTouch;



// AIzaSyD81kQJOiSNt-OGB6U9CY0wy_cbkMkj2Tg
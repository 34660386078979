import { Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";

//IMPORT BASEURL
import baseURL from '../../api/api';

//ICON IMPORTS
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

//MODAL IMPORTS
import Modal from "@mui/material/Modal";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';





const EventTable = () => {


  //ADD MODAL
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const handleOpenAddModal = () => {
    clearStates();
    setOpenAddModal(true);
  }
  const handleCloseAddModal = () => setOpenAddModal(false);

  //DELETE DIALOG
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleDeleteDialogOpen = (id) => {
    setEventID(id)
    setOpenDeleteDialog(true);
  };
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  //EDIT DIALOG
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleOpenEditModal = async (id) => {
    setEventID(id);
    await getUpdateEvent(id);
    setOpenEditModal(true);
  }
  const handleCloseEditModal = () => {
    setOpenEditModal(false)
  }


  //GET EVENTS DATA
  const [myEvents, setMyEvents] = useState([]);
  useEffect(() => getEvents(), []);

  const getEvents = async () => {
    const response = await baseURL.get('/institute/home/events/findAll');
    setMyEvents(response.data);
  }

  const renderedEvents = myEvents.map(({ id, date, location, time, title }, index) => {

    const theDate = new Date(date);
    let theMonth = theDate.toLocaleString('en-us', { month: 'long' });
    let theDay = theDate.getDate();
    let theYear = theDate.getFullYear();


    // const [theHour, theMinute] = time.split(':');

    let theHour = time.substring(0, 2);
    let theMinute = time.substring(3, 5);
    const formatAMPM = () => {
      var ampm = theHour >= 12 ? 'PM' : 'AM';
      theHour = theHour % 12;
      theHour = theHour ? theHour : 12; // the hour '0' should be '12'
      theHour = theHour < 10 ? `0${theHour}` : theHour;
      theMinute = theMinute < 10 ? theMinute : theMinute;
      var strTime = theHour + ':' + theMinute + ' ' + ampm;
      return strTime;
    }




    return (
      <tr key={id}>
        <td className="tableCellData">{index + 1}.</td>
        <td className="imagePreviewContainer tableCellData">{title}</td>
        <td className="tableCellData">
          {theMonth} {theDay}, {theYear}
        </td>
        <td className="tableCellData">
          {formatAMPM()}
          {/* {time} */}
        </td>
        <td className="tableCellData">
          {location}
        </td>
        <td>
          <IconButton color="secondary" onClick={() => handleOpenEditModal(id)}>
            <EditIcon fontSize="medium" />
          </IconButton>
        </td>
        <td>
          <IconButton color="error">
            <DeleteIcon
              fontSize="medium"
              onClick={() => handleDeleteDialogOpen(id)}
            />
          </IconButton>
        </td>
      </tr>
    )
  })

  const [date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [time, setTime] = useState('');
  const [title, setTitle] = useState('');
  const [value, setValue] = React.useState(null);


  const onInputChange = (e) => {
    // e.preventDefault();
    console.log(e.target.label, 'MY TARGET')
    if (e.target.id === 'eventTitle') {
      setTitle(e.target.value);
    }
    if (e.target.id === 'eventDate') {
      setDate(e.target.value)
    }
    if (e.target.id === 'eventTime') {
      setTime(e.target.value)
    }
    if (e.target.id === 'eventLocation') {
      setLocation(e.target.value)
    }
  }

  const mediaSubmit = (e) => {
    e.preventDefault();

    const events = {
      date,
      location,
      time,
      title
    }


    baseURL.post('/institute/home/events/save', events).then(response => {
      getEvents().then(() => setOpenAddModal(false))
    }).catch((err) => console.log(err, 'err err '))
  }

  //DELETE EVENT
  const [eventID, setEventID] = useState();
  const deleteEvent = (id) => {
    baseURL.delete(`/institute/home/events/delete/${id}`).then(res => {
      getEvents().then(() => setOpenDeleteDialog(false))
    });
  }


  //UPDATE EVENTS 
  const getUpdateEvent = async (eventID) => {
    await baseURL.get(`/institute/home/events/findById/${eventID}`).then((res) => {
      const { date } = res.data;
      const theDate = new Date(date);
      let theYear = theDate.getFullYear();
      let theMonth = theDate.getMonth();
      let theDay = theDate.getDate();

      let theFullDate = `${theYear}-${theMonth}-${theDay}`;

      setTitle(res.data.title);
      setDate(res.data.date);
      setTime(res.data.time);
      setLocation(res.data.location);

    })
  }

  const mediaUpdate = (e) => {
    e.preventDefault();
    const events = {
      date,
      location,
      time,
      title
    }
    baseURL.put(`/institute/home/events/update/${eventID}`, events).then(response => {
      getEvents().then(() => setOpenEditModal(false))
    }).catch((err) => console.log(err, 'err err '))
  }


  //CLEAR STATES
  const clearStates = () => {
    setTitle('')
    setDate('')
    setTime('')
    setLocation('')
  }


  //USING LATER
  // const handleChange = (newValue) => {
  //   // setValue(newValue);

  //   setValue(newValue);

  //   const theDate = new Date(newValue);
  //   let theHour = theDate.getHours(); // => 9
  //   let theMinute = theDate.getMinutes(); // =>  30

  //   console.log(theHour + ':' + theMinute, 'buzzer')

  //   const theTime=theHour+':'+theMinute;

  //   // var ampm = theHour >= 12 ? 'PM' : 'AM';
  //   // theHour = theHour % 12;
  //   // theHour = theHour ? theHour : 12; // the hour '0' should be '12'
  //   // theHour = theHour < 10 ? `0${theHour}` : theHour;
  //   // theMinute = theMinute < 10 ? theMinute : theMinute;
  //   // var strTime = theHour + ':' + theMinute + ' ' + ampm;
  //   // // return strTime;
  //   // // setValue(newValue);

  //   // console.log(strTime, 'MY TIME')

  //   // setTime(theTime)

  // };


  return (
    <>
      <div className="tabPanelHeader">
        <h3>Events Table</h3>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
          className="addButton"
        >
          Add
        </Button>
      </div>
      <div>
        <table>
          <thead className="eventTableHead">
            <th>SN</th>
            <th>Event Title</th>
            <th>Event Date</th>
            <th>Event Time</th>
            <th>Venue</th>
            <th>Edit</th>
            <th>Delete</th>
          </thead>
          <tbody>
            {renderedEvents}
          </tbody>
        </table>

        <Modal
          open={openEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="updateModal"
        >
          <div className="addNewTeamModalPopUp">
            <div className="modalHeader">
              <h3>Update Image</h3>
            </div>
            <div className="modalContainer">
              <FormControl className="modalContent" component="form" onSubmit={(e) => mediaUpdate(e)}>
                <div className="textFieldContainer">
                  <TextField
                    label="Event Title"
                    id="eventTitle"
                    onChange={onInputChange}
                    value={title}
                    variant="standard"
                    className="textField"
                    required
                  />
                  <TextField
                    label="Event Date"
                    id="eventDate"
                    onChange={onInputChange}
                    type="date"
                    value={date || ''}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    className="textField"
                  // required
                  />

                  <TextField
                    label="Event Time"
                    id="eventTime"
                    onChange={onInputChange}
                    type="time"
                    value={time}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    className="textField"
                    required
                  />
                  <TextField
                    label="Event Venue"
                    id="eventLocation"
                    onChange={onInputChange}
                    value={location}
                    variant="standard"
                    className="textField"
                    required
                  />
                </div>
                <div className="submitContainer">
                  <Button variant="contained" color="error" onClick={clearStates}>
                    CLEAR
                  </Button>
                  <Button variant="contained" type="submit" >UPDATE</Button>
                </div>
              </FormControl>
            </div>
          </div>
        </Modal>

        <Modal
          open={openAddModal}
          onClose={handleCloseAddModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="updateModal"
        >
          <div className="addNewTeamModalPopUp">
            <div className="modalHeader">
              <h3>Add Event</h3>
            </div>
            <div className="modalContainer">
              <FormControl className="modalContent" component="form" onSubmit={(e) => mediaSubmit(e)}>
                <div className="textFieldContainer">
                  <TextField
                    label="Event Title"
                    id="eventTitle"
                    onChange={onInputChange}
                    value={title}
                    variant="standard"
                    className="textField"
                    required
                  />

                  <TextField
                    label="Event Date"
                    id="eventDate"
                    onChange={onInputChange}
                    type="date"
                    value={date || ''}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    className="textField"
                    required
                  />

                  <TextField
                    label="Event Time"
                    id="eventTime"
                    onChange={onInputChange}
                    type="time"
                    value={time}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    className="textField"
                    required
                  />
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="Time"
                      value={value}
                      onChange={handleChange}
                      // onChange={handleChange}
                      // value={time}

                      // value={value}
                      // onChange={(newValue) => {
                      //   setValue(newValue);
                      // }}

                      renderInput={(params) => <TextField id="eventTime"
                        variant="standard"
                        {...params} />}
                    />
                  </LocalizationProvider> */}
                  <TextField
                    label="Event Venue"
                    id="eventLocation"
                    onChange={onInputChange}
                    value={location}
                    variant="standard"
                    className="textField"
                    required
                  />


                </div>
                <div className="submitContainer">
                  <Button variant="contained" color="error" onClick={clearStates}>
                    CLEAR
                  </Button>
                  <Button variant="contained" type="submit" >SUBMIT</Button>
                </div>
              </FormControl>
            </div>
          </div>
        </Modal>

        <Dialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure want to delete the selected data?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleDeleteDialogClose}
              variant="contained"
              color="primary"
            >
              NO
            </Button>
            <Button
              onClick={() => { deleteEvent(eventID) }}
              variant="contained"
              color="error"
              autoFocus
            >
              YES
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default EventTable;

import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


import logo from "../../../assets/images/NAVC WHITE.png";
import whiteLogo from '../../../assets/images/whiteLogo.png';
import token from '../../../assets/images/token.png';
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";

import {
  BrowserRouter as Router,
  Link,
  NavLink,
} from "react-router-dom";


//IMPORT BASEURL
import baseURL from '../../../api/api';



const Footer = () => {

  const [country, setCountry] = useState([]);
  const [service, setService] = useState([]);


  useEffect(() => getCountry(), []);
  const getCountry = async () => {
    const response = await baseURL.get('/institute/country/studyLevel/findAll').then((res) => {
      // console.log(res.data, 'GOBLIN')

      setCountry(res.data)

    });
  }


  const renderedCountry = country.slice(0, 14).map((data) => {

    return (
      <li>
        <Link key={data.id} to={`/universities/${data.countryName}`} className="footerCountryLink">
          Study in  {data.countryName}
        </Link>
      </li>
    )
  });

  useEffect(() => {
    getService();
  }, [])

  const getService = async () => {
    const { data: responseData = {} } = await baseURL.get('/institute/ourService/findAll');
    let modifiedData = [];
    responseData.map((data) => {
      let { mainHeading } = data;
      mainHeading = mainHeading.trim().toLowerCase();
      if (mainHeading === "ielts") {
        modifiedData.splice(0, 0, data);
      } else if (mainHeading === "pte") {
        let [{ mainHeading: mh }] = modifiedData;
        mh = mh.trim().toLowerCase();
        if (mh === "ielts") {
          modifiedData.splice(1, 0, data);
        } else {
          modifiedData.splice(0, 0, data);
        }
      } else {
        modifiedData.push(data);
      }
    });
    setService(modifiedData);
  }

  const renderService = service.map((data, index, arr) => {
    return (
      <li>
        <Link to={`/service/${data.id}`}>{data.mainHeading}</Link>
      </li>
    )
  })


  let history = useHistory();
  const pathname = window.location.pathname
  if (history.location.pathname === '/admin' || history.location.pathname === '/adminpanel') {
    return null
  }

  else {
    return (
      <div className="footer">
        <div className="footerContacts">
          <div className="footerContactsLogo">
            <Link to="/" className="footerLogoContainer">
              <img src={whiteLogo} className="footerContactsLogoImg" />
            </Link>
          </div>
          <div className="footerContactsContainer">
            <div className="footerContactTitle">
              <h3>Contact</h3>
            </div>
            <div className="footerContactContainer">
              <div className="footerContactCard">
                <div className="footerContactIconHolder">
                  <PhoneIcon fontSize="small" />
                </div>
                <div className="footerContactMetaData">
                  <p><a href="tel:01-5551086" style={{ color: 'white' }}>01-5551086 </a></p>
                </div>
              </div>
              <div className="footerContactCard">
                <div className="footerContactIconHolder">
                  <EmailIcon fontSize="small" />
                </div>
                <div className="footerContactMetaData">
                  <p className="contactAddress"><a href="mailto:info@northernstar.com.np" style={{ color: 'white' }}>info@northernstar.com.np</a> </p>
                </div>
              </div>
              <div className="footerContactCard">
                <div className="footerContactIconHolder">
                  <LocationOnIcon fontSize="small" />
                </div>
                <div className="footerContactMetaData">
                  <p>Jawalakhel, Lalitpur, Nepal</p>
                </div>
              </div>
            </div>
            <div className="socialMediaContainerFooter">
              <a href="https://www.facebook.com/northernstaredu" target="_blank" className="socialMediaLink socialLinkFacebook">
                <i className="fab fa-facebook-f"></i>
              </a>
              {/* <a href="#" className="socialMediaLink socialLinkTwitter">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="socialMediaLink socialLinkYoutube">
                <i className="fab fa-youtube"></i>
              </a> */}
              <a href="https://www.instagram.com/northernstaredunepal/" target="_blank" className="socialMediaLink socialLinkInstagram">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="footerContents">
          <div className="footerLinksContainer">
            <div className="footerLinksTitle">
              <h1>Quick Links</h1>
            </div>
            <div className="footerLinks">
              <ul className="footerUL">
                <li>
                  <Link to="/aboutus">About NSEC</Link>
                </li>
                <li>
                  <Link>Online Application</Link>
                </li>
                <li>
                  <Link to="/ourservices">Services</Link>
                </li>
                <li>
                  <Link to="/contactus">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footerLinksContainer">
            <div className="footerLinksTitle">
              <h1>Services</h1>
            </div>
            <div className="footerLinks">
              <ul className="footerUL">
                {renderService}
                {/* <li>
                  <Link to="/aboutus">SOV Letter</Link>
                </li>
                <li>
                  <Link to="/">CV Letter</Link>
                </li>
                <li>
                  <a href="https://www.hamropatro.com/date-converter" target="_blank">Date Converter</a>
                </li>
                <li>
                  <a href="https://www.xe.com/currencyconverter/" target="_blank">Currency Converter</a>
                </li>
                <li>
                  <a href="#" target="_blank">Experience Letter</a>
                </li>
                <li>
                  <a href="#" target="_blank">Recommendation Letter</a>
                </li>
                <li>
                  <a href="#" target="_blank">SOP</a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="footerLinksContainer">
            <div className="footerLinksTitle">
              <h1>Student Needs</h1>
            </div>
            <div className="footerLinks">
              <ul className="footerUL">
                <li>
                  <a href="https://studyabroad.shiksha.com/sop-sample-applycontent1727" target="_blank">SOP</a>
                </li>
                <li>
                  <a href="https://resumegenius.com/blog/cover-letter-help/how-to-write-a-cover-letter" target="_blank">CV Letter</a>
                </li>
                <li>
                  <a href="https://www.hamropatro.com/date-converter" target="_blank">Date Converter</a>
                </li>
                <li>
                  <a href="https://www.xe.com/currencyconverter/" target="_blank">Currency Converter</a>
                </li>
                <li>
                  <a href="https://leverageedu.com/blog/experience-letter/" target="_blank">Experience Letter</a>
                </li>
                <li>
                  <a href="https://www.coursera.org/articles/how-to-write-a-letter-of-recommendation-template-tips" target="_blank">Recommendation Letter</a>
                </li>

              </ul>
            </div>
          </div>
          <div className="footerLinksContainer">
            <div className="footerLinksTitle">
              <h1>Study Abroad</h1>
            </div>
            <div className="footerLinks">
              <div className="footerCountries">
                <ul className="footerUL footerCountryCol">
                  {renderedCountry}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }

};

export default Footer;


import baseURL from '../../../api/api';
import React, { useState, useEffect } from "react";
import { Grid } from '@mui/material';


import {
    BrowserRouter as Router,
    useParams
} from "react-router-dom";




const ServiceDetails = () => {
    let { id } = useParams();


    const [service, setService] = useState([]);

    useEffect(() => getService(), id);



    const getService = async () => {
        await baseURL.get(`/institute/ourService/findById/${id}`).then((res) => {
            setService([res.data]);
        })
    }

    const renderedService = service.map((data) => {

        const renderedPara = data.ourServiceContentHeadings[0].ourServiceContents.map(({ contentType, text }) => {
            if (contentType == 'title') {
                return <h3 style={{ marginTop: '16px', textAlign: 'justify' }}>{text} <br /> </h3>
            }
            if (contentType === 'para') {
                return <p style={{ textAlign: 'justify' }}>{text} <br /> </p>
            }
            if (contentType === 'list') {
                return <li style={{ marginLeft: '16px' }}>{text}</li>
            }
            return null
        })


        return (

            <Grid key={id}>
                <Grid container item>
                    <h1>{data.mainHeading}</h1>
                </Grid>
                <Grid container item>
                    <img src={data.image} style={{ width: '100%' }} />
                </Grid>
                <Grid container item style={{ display: 'flex', flexDirection: 'column' }}>
                    {renderedPara}
                </Grid>
            </Grid>
        )
    })

    return (
        <Grid container item className="serviceDetail">
            {renderedService}
        </Grid>

    )
}

export default ServiceDetails;
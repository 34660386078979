import { Divider, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";

//IMPORT BASEURL
import baseURL from '../../api/api';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";

const NewsAndEvents = () => {

  //GET EVENTS DATA
  const [myEvents, setMyEvents] = useState([]);
  useEffect( () =>  getEvents(), []);
  const getEvents = async () => {
    const response = await baseURL.get('/institute/home/events/findAll');
    setMyEvents(response.data);
  }
  const renderedEvents = myEvents.map(({ id, date, location, time, title }, index) => {
    const theDate = new Date(date);

    let theMonth = theDate.toLocaleString('en-us', { month: 'short' });
    let theDay = theDate.getDate();


    let theHour = time.substring(0, 2);
    let theMinute = time.substring(3, 5);

    const formatAMPM = () => {
      var ampm = theHour >= 12 ? 'PM' : 'AM';
      theHour = theHour % 12;
      theHour = theHour ? theHour : 12; // the hour '0' should be '12'
      theHour = theHour < 10 ? `0${theHour}` : theHour;
      theMinute = theMinute < 10 ? + theMinute : theMinute;
      var strTime = theHour + ':' + theMinute + ' ' + ampm;
      return strTime;
    }

    return (
      <>
        <Grid
          container
          item
          direction={{ xs: "row" }}
          className="pnEventCard"
          key={id}
        >
          <Grid
            container
            item
            xs={1}
            direction={{ xs: "column" }}
            justifyContent={{ xs: "center" }}
            alignItems={{ xs: "flex-start" }}
            className="pnEventDate"
          >
            <Grid
              container
              item
              justifyContent={{ xs: "center" }}
              alignItems={{ xs: "center" }}
              className="pnEventDay"
            >
              <h1>{theDay}</h1>
            </Grid>
            <Grid
              container
              item
              justifyContent={{ xs: "center" }}
              alignItems={{ xs: "center" }}
              className="pnEventMonth"
            >
              <h3>{theMonth}</h3>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={11}
            direction={{ xs: "column" }}
            className="pnEventDetails"
          >
            <Grid container item xs="auto" className="pnEventTitle">
              <h3>{title}</h3>
            </Grid>
            <Grid
              container
              item
              xs="auto"
              direction={{ xs: "column" }}
              className="pnEventBrief"
            >
              <Grid
                container
                item
                xs="auto"
                direction={{ xs: "row" }}
                className="pnEventMetaDataHolder"
              >
                <Grid
                  container
                  item
                  xs="auto"
                  className="pnEventMetaDataIconHolder"
                  justifyContent={{ xs: "center" }}
                  alignItems={{ xs: "center" }}
                >
                  <AccessTimeIcon
                    fontSize="small"
                    className="pnEventMetaDataIcon"
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs="auto"
                  className="pnEventMetaData"
                >
                  <p>{formatAMPM()}</p>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs="auto"
                direction={{ xs: "row" }}
                className="pnEventMetaDataHolder"
              >
                <Grid
                  container
                  item
                  xs="auto"
                  className="pnEventMetaDataIconHolder"
                  justifyContent={{ xs: "center" }}
                  alignItems={{ xs: "center" }}
                >
                  <LocationOnIcon
                    fontSize="small"
                    className="pnEventMetaDataIcon"
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs="auto"
                  className="pnEventMetaData"
                >
                  <p>{location}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider className="eventCardDivider" />
      </>
    )
  }
  )

  //GET NEWS DATA
  const [news, setNews] = useState([]);
  useEffect(() => getNews(), []);
  const getNews = async () => {
    const response = await baseURL.get('/institute/home/news/findAll');
    setNews(response.data.reverse());
  }

  const renderedNews = news.map(({ id, image, date, newsContentHeadings }, index) => {
    const theDate = new Date(date);
    let theMonth = theDate.toLocaleString('en-us', { month: 'long' });
    let theWeekDay = theDate.toLocaleDateString('en-US', { weekday: 'long' })
    let theDay = theDate.getDate();
    let theYear = theDate.getFullYear();


    const renderedNewsContentHeadings = newsContentHeadings.map(({ id, contentType, heading, newsContents }) => {

      const renderedNewsContents = newsContents.map(({ text }) => {
        return text;
      })
      return [heading, renderedNewsContents];
    });

    const renderedNews = renderedNewsContentHeadings[0][1].map((data) => {
      return (
        <span>{data}</span>
      )
    })
    return (
      <Grid
        container
        item
        xs="auto"
        direction={{ xs: "column", sm: "row" }}
        className="pnNewsCard"
        key={id}
      >
        <Grid
          container
          item
          xs={12}
          sm={3.5}
          md={4}
          lg={4}
          // justifyContent={{ xs: "center" }}
          alignItems={{ xs: "center" }}
          className="pnNewsImage"
        >
          <img src={image} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={8.5}
          md={8}
          lg={8}
          direction={{ xs: "column" }}
          className="pnNewsDescription"
        >
          <p className="pnNewsDate" style={{ fontSize: '14px', fontWeight: '400' }}>{theWeekDay}, {theMonth} {theDay}, {theYear}</p>
          <p className="pnNewsTitle" style={{ fontWeight: '400' }}>
            {renderedNewsContentHeadings[0][0]}
          </p>
          <p className="para-clamping pnNewsPara threeLineClamp">
            {renderedNews}
          </p>
          <div className="pnNewsSeeMore">
            <Link to={`/news/${id}`} className="navigateLink">
              Read More &gt;&gt;
            </Link>
          </div>
        </Grid>
      </Grid >
    )
  })

  return (
    <Grid container direction={{ xs: "column" }} className="pnNewsAndEvents">
      <Grid
        container
        item
        xs="auto"
        justifyContent="center"
        alignItems="center"
        className="pnNewsAndEventsTitle"
      >
        <h1>NEWS & EVENTS</h1>
      </Grid>
      <Grid
        container
        item
        xs="auto"
        direction={{ xs: "column", lg: "row" }}
        className="pnNewsAndEventSection"
      >
        <Grid
          container
          item
          xs="auto"
          direction={{ xs: "column" }}
          lg={8}
          className="pnNewsSection"
        >
          <Grid container item className="pnNewsTitle">
            <h3>NEWS</h3>
          </Grid>
          <Grid
            container
            item
            direction={{ xs: "column" }}
            className="pnNewsContainer"
          >
            {renderedNews}
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs="auto"
          lg={4}
          direction={{ xs: "column" }}
          className="pnEventSection"
        >
          <Grid container item xs="auto" direction={{ xs: "column" }}>
            <h3>EVENTS</h3>
          </Grid>
          <Grid
            container
            item
            xs="auto"
            direction={{ xs: "column" }}
            className="pnEventCardHolder"
          >
            {renderedEvents}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewsAndEvents;

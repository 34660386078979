import React from "react";
import TopNavBar from "./components/common/header/TopNavBar";
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";

//Routing Components import
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./Routes/PrivateRoute";
import AboutUs from "./components/explorerPages/AboutUs";
import Contact from "./components/explorerPages/Contact";
import Service from "./components/explorerPages/Services";
import NewsAndEvents from "./components/explorerPages/NewsAndEvents";
import Landing from "./components/main/Landing";
import ScrollToTop from "./components/config/scrollToTop";
import Detail from "./components/explorerPages/Detail";
import Universities from "./components/explorerPages/Universities";
import AdminPanel from "./components/Admin/AdminPanel";
import AdminForm from './components/Admin/AdminForm';


import News from './components/common/innerComponents/News';
import ServiceDetails from './components/common/innerComponents/ServiceDetails';
import CountryDetails from './components/common/innerComponents/CountryDetails';
import UniversityDetails from './components/common/innerComponents/UniversityDetails';





const App = (props) => {
  return (
    <>
      <Router>
        <TopNavBar />
        <Header />
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/aboutus" component={AboutUs} />
            <Route exact path="/ourservices" component={Service} />
            <Route exact path="/newsandevents" component={NewsAndEvents} />
            <Route exact path="/contactus" component={Contact} />
            <Route exact path="/detail" component={Detail} />
            <Route exact path="/universities/:country" component={Universities} />
            <Route exact path="/universitydetails/:id" component={UniversityDetails} />
            <Route exact path="/news/:id" component={News} />
            <Route exact path="/service/:id" component={ServiceDetails} />
            <Route exact path="/country/:name" component={CountryDetails} />
            <Route exact path="/admin" component={AdminForm} />
            <PrivateRoute exact path="/adminpanel" component={AdminPanel} />
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </ScrollToTop>
        <Footer />
      </Router>
    </ >
  );
};

export default App;


// #9b1d21
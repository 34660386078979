import { Grid } from "@mui/material";
import React from "react";
import StudyInAus from "../../assets/images/Study-in-Australia.jpg";

const Detail = () => {
  return (
    <Grid container direction={{ xs: "column" }} className="details">
      <Grid container item xs="auto" className="detailsImageSection">
        <Grid container item>
          <img src={StudyInAus} />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs="auto"
        direction={{ xs: "column" }}
        className="countryDetail"
      >
        <Grid container item xs="auto" className="countryDetailTitle">
          <h3>ABOUT AUSTRALIA</h3>
        </Grid>
        <Grid container item xs="auto" className="countryDetailPara1">
          <p>
            Study in Australia from Nepal is the right decision to make for
            abroad education.Did you know that Australia is the 6th biggest
            country in the world by land mass but is ranked 55 in term of
            population with an approximate count of 25 million people and
            contributes less than 0.35% of the world’s population? Modern
            Australia evolved to what it is today only after 1901 when 6
            colonies federation to from the commonwealth of Australia and till
            today millions of people aspire to move to Australia weather its for
            education, employment or simply migrate. Some of the top most
            livable cities in the world are in Australia and so does some of the
            most highly ranked universities who excel in research which have
            brought a positive change in the world that we live in.
          </p>
        </Grid>
        <Grid container item xs="auto" className="countryDetailPara2">
          <p>
            Australia today has become one of the most preferred destination for
            international students and it certainly not going to stop anytime
            soon. With superior standard education, high quality lifestyle,
            provision for international students to work part time, provision of
            post study work option for Australian degree holders, option to
            bring the students dependent partner (spouse) during study and many
            other factors make Australia one amazing destination presenting a
            diverse multicultural environment for the students to get study in.
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs="auto"
        direction={{ xs: "column" }}
        className="detailCountryFacts"
      >
        <Grid container item xs="auto" className="detailCountryFactTitle">
          <h3>INTERESTING FACTS ABOUT AUSTRALIA</h3>
        </Grid>
        <Grid container item xs="auto" className="detailCountryFactLists">
          <ol>
            <li>6th largest country in the world</li>
            <li>
              Population about 25.5 million that is less than Nepal's
              population.
            </li>
            <li>
              Australia Ranks among the top 3 for the highest human development
            </li>
            <li>Australia has over 10000 beaches </li>
            <li>Australian alps receive more snow than Switzerland </li>
            <li>he are more sheep in Australia than humans (8:1 ratio) </li>
            <li>
              Sydney is the largest city but not the capital. Its Canberra.
            </li>
            <li>Some of the deadliest animals live there </li>
          </ol>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs="auto"
        direction="column"
        className="detailRemoteStudy"
      >
        <Grid container item xs="auto" className="detailRemoteStudyTitle">
          <h3>STUDY IN AUSTRALIA FROM NEPAL</h3>
        </Grid>
        <Grid container item xs="auto" className="detailRemoteStudyDescription">
          <p>
            Studying in Australia from Nepal is the best decision you ever made.
            The Australian education system provides primary, secondary and
            tertiary education. If you are considering to study in Australia you
            should know, the quality of education is of world class and is in
            very high demand. The qualification of the Australian education and
            training system is maintained by a policy called the Australian
            Qualification Framework AQF. There are 10 levels in the AQF and can
            be easily understood following the diagrams displayed below:
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Detail;

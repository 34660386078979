import React from "react";
import { Button, Grid } from "@mui/material";
import director from "../../assets/images/director.png";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import directorImg from '../../assets/images/director1.jpeg';
import Slider from "react-slick";
import intropic from '../../assets/images/intropic.jpeg';
//IMPORT BASEURL
import baseURL from '../../api/api';



import CountUp from 'react-countup';


const WhyChooseUs = () => {


  const [introSlider, setIntroSlider] = useState([]);
  useEffect(() => getIntroSlider(), []);

  const getIntroSlider = async () => {
    const response = await baseURL.get('/institute/home/flowSlider/findAll');
    setIntroSlider(response.data)
  }


  const renderedIntroSlider = introSlider.map(({ id, fileName, image }) => {
    return (
      <img src={image} key={id} />
    )
  });


  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    adaptiveHeight: true,
    arrows: false
  };



  return (

    <Grid container item direction={{ xs: "column" }} className="whyChooseUs">
      <Grid container item xs className="whyChooseUsTitleM whyChooseUsTitle">
        <h1>WHY CHOOSE US?</h1>
      </Grid>
      <Grid container item xs direction={{ xs: "column" }} className="whyChooseSliderDesc">
        <Grid container item xs sm direction={{ xs: 'column' }} justifyContent={{ xs: 'flex-start' }} alignItems={{ xs: 'center' }} className="whyChooseSliderContainer">
          <Slider {...settings}>
            {renderedIntroSlider}
          </Slider>
        </Grid>
        <Grid container item xs sm direction={{ xs: "column" }} className="whyChooseTitleDesc">
          <Grid container item className="whyChooseUsTitleS whyChooseUsTitle">
            <h1>WHY CHOOSE US?</h1>
          </Grid>
          <Grid container item className="whyChooseUsDescription">
            <p style={{ textAlign: 'justify' }}>
              Applying for higher education abroad is a big decision. It is imperative to invest adequate time and effort in order to make the right decision. Every students are unique and exceptional. Approaches based on one size fits all can never help you achieve your greatest potential. With almost a decade of expertise, a team dedicated for client satisfaction and hundreds of universities and programs in our list, NSEC is dedicated to distinguish such differences and provide solution based on your interest.
            </p>

            <p style={{ textAlign: 'justify', marginTop: '8px' }}>
              Our expert consultants can hold your hand through the entire process helping you make the right decision every step of the way based on your preference ensuring that you emerge as a winner in the end.
            </p>
          </Grid>
          <Grid container item className='whyChooseUsReadMore' direction={{ xs: 'row' }} justifyContent={{ xs: 'center', lg: 'flex-end' }}>
            <button className="readMoreButton" >Read More</button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item style={{ backgroundColor: 'maroon' }} className="whyChooseUsDataSection">
        <Grid container item direction={{ xs: 'row' }} justifyContent={{ xs: 'center' }} alignItems={{ xs: 'center' }} className="whyChooseUsDataContainer">
          <Grid container item xs direction={{ xs: 'column' }} justifyContent={{ xs: 'center' }} alignItems={{ xs: 'center' }}>
            <GroupsIcon className="whyChooseUsIcon" />
            <h1 data-target="5980" className="count">
              <CountUp delay={1} start={0} end={5980} duration={2.75} />
            </h1>
            <h3>STUDENTS</h3>
          </Grid>
          <Grid container item xs direction={{ xs: 'column' }} justifyContent={{ xs: 'center' }} alignItems={{ xs: 'center' }}>
            <AccountBalanceIcon className="whyChooseUsIcon" />
            <h1><CountUp delay={1} start={0} end={200} duration={2.75} />
            </h1>
            <h3>UNIVERSITIES</h3>
          </Grid>
          <Grid container item xs direction={{ xs: 'column' }} justifyContent={{ xs: 'center' }} alignItems={{ xs: 'center' }}>
            <CelebrationIcon className="whyChooseUsIcon" />
            <h1><CountUp delay={1} start={0} end={20} duration={3} />
            </h1>
            <h3>YEARS</h3>
          </Grid>
        </Grid>
      </Grid>



    </Grid>

  )
};


export default WhyChooseUs;






import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Slider from "react-slick";

//IMPORT BASEURL
import baseURL from '../../../api/api.js';



const MeetOurTeam = () => {

  const [teamSlider, setTeamSlider] = useState([]);
  useEffect(() => getTeamSlider(), []);

  const getTeamSlider = async () => {
    const response = await baseURL.get('/institute/home/team/findAll');
    setTeamSlider(response.data)
  }

  const renderedTeamSlider = teamSlider.map(({ id, fullName, designation, image, teamSocialMedias }) => {
    return (
      // <Grid className="ourTeamsImageContainer" key={id} >
      //   <div className="myTeamImgHolder">
      //     <img src={image} style={{ height: "20px" }} />
      //     <div className="ourTeamHover">
      //       <div className="ourTeamIconContainter">
      //         {(teamSocialMedias[0].path !== '') ? <a target="_blank" href={teamSocialMedias[0].path} className="socialMediaLink socialLinkFacebook">
      //           <i className="fab fa-facebook-f hoverSocialMediaIcon"></i>
      //         </a> : null}
      //         {(teamSocialMedias[1].path !== '') ? <a target="_blank" href={teamSocialMedias[1].path} className="socialMediaLink socialLinkFacebook">
      //           <i className="fab fa-twitter hoverSocialMediaIcon"></i>
      //         </a> : null}
      //         {(teamSocialMedias[2].path !== '') ? <a target="_blank" href={teamSocialMedias[2].path} className="socialMediaLink socialLinkFacebook">
      //           <i className="fab fa-instagram hoverSocialMediaIcon"></i>
      //         </a> : null}
      //       </div>
      //     </div>
      //   </div>
      //   <div className="ourTeamsBioData">
      //     <h3>{fullName}</h3>
      //     <p>{designation}</p>
      //   </div>
      // </Grid >

      <div key={id} className="myteamCard">
        <div className="myTeamImageHolder">
          <img src={image} />
          <div className="ourTeamHover">
            <div className="innerTeamHover">
              <div className="ourTeamIconContainter">
                {(teamSocialMedias[0].path !== '') ? <a target="_blank" href={teamSocialMedias[0].path} className="socialMediaLink socialLinkFacebook">
                  <i className="fab fa-facebook-f hoverSocialMediaIcon"></i>
                </a> : null}
                {(teamSocialMedias[1].path !== '') ? <a target="_blank" href={teamSocialMedias[1].path} className="socialMediaLink socialLinkFacebook">
                  <i className="fab fa-twitter hoverSocialMediaIcon"></i>
                </a> : null}
                {(teamSocialMedias[2].path !== '') ? <a target="_blank" href={teamSocialMedias[2].path} className="socialMediaLink socialLinkFacebook">
                  <i className="fab fa-instagram hoverSocialMediaIcon"></i>
                </a> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="myTeamBioHolder">
          <h5 className="myTeamName">{fullName.toUpperCase()}</h5>
          <h5 className="myTeamDesignation">{designation.toUpperCase()}</h5>
        </div>
      </div>

    )
  });


  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed:1000,
    autoplaySpeed:5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  return (
    // <Grid container direction={{ xs: "row" }} className="ourTeams" style={{ border: '3px solid red' }}>
    //   <Grid xs={12} item className="ourTeamsTitle">
    //     <h5 className="ourTeamsHeading">MEET OUR TEAM</h5>
    //   </Grid>
    //   <Grid xs={12} item className="ourTeamsSlider">
    //     <Slider {...settings} className="ourTeamsSliderGrid">
    //       {renderedTeamSlider}
    //     </Slider>
    //   </Grid>
    // </Grid>

    <div className="meetOurTeam">
      <div className="meetOurTeamTitleHolder">
        <h5>MEET OUR TEAM</h5>
      </div>
      <div className="meetOurTeamCardHolder">
        <Slider {...settings}>
          {renderedTeamSlider}
        </Slider>
      </div>
    </div>
  );
};
export default MeetOurTeam;

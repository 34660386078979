import { Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";

//IMAGE IMPORTS
import slideMedium from "../../assets/images/slide-medium1.png";
import defaultImage from '../../assets/images/defaultImage.png';

//IMPORT BASEURL
import baseURL from '../../api/api';

//ICON IMPORTS
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';

//MODAL IMPORTS
import Modal from "@mui/material/Modal";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';





const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const StudyLevelTable = () => {
    //ADD MODAL
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const handleOpenAddModal = () => {
        clearStates();
        getStudyLevel();
        getCountries();
        setOpenAddModal(true);
    }
    const handleCloseAddModal = () => setOpenAddModal(false);

    //UPDATE MODAL
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const handleOpenEditModal = (countryName, ID) => {
        setLevelID(ID);
        getUpdateLevel(countryName);
        setOpenEditModal(true);

    };
    const handleCloseEditModal = () => {
        setOpenEditModal(false);


    }


    //DELETE DIALOG 
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const handleDeleteDialogOpen = (id) => {
        setLevelID(id);
        setOpenDeleteDialog(true);
    };
    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };


    const names = [
        'Graduate',
        'Undergraduate',
        'Master'
    ];

    function getStyles(name, levelName, theme) {
        return {
            fontWeight:
                levelName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }


    //INITIAL CREATE STATE
    const theme = useTheme();
    const [theNames, setTheNames] = useState([
        'Graduate',
        'Undergraduate',
        'Master'
    ]);
    const [levelID, setLevelID] = useState('');
    const [countryLogo, setCountryLogo] = useState('');
    const [theCountryName, setTheCountryName] = useState('');
    const [levelName, setLevelName] = React.useState([]);
    const [tryCountryList, setTryCountryList] = useState([{}]);
    const [newStudyLevel, setNewStudyLevel] = useState('');


    const getCountries = async () => {
        const response = await baseURL.get(`/institute/country/findAll`).then((res) => {
            const tryCountryNames = res.data.map(({ countryName, logo }) => {
                return { countryName, logo };
            })
            setTryCountryList(tryCountryNames);
            // setCountryList(countryNames)
            return 'apple'
        })
    }

    const renderedTryCountries = tryCountryList.map((data,index) => {
        return (
            <MenuItem value={data.countryName} key={index}>{data.countryName}</MenuItem>
        )
    })

    const setLogo = (value) => {
        tryCountryList.forEach((element, index, array) => {
            if (element.countryName === value) {
                setCountryLogo(element.logo)
            }
        })
    }

    const handleChange = (event) => {
        if (event.target.name === "countryName") {
            setTheCountryName(event.target.value);
            setLogo(event.target.value);
        }
        if (event.target.name === "studyLevel") {
            const { target: { value } } = event;
            setLevelName(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    };

    //ADD LEVEL
    const addLevel = () => {
        // setTheNames(prevValue=>[...prevValue])
        setTheNames(prevValue => [...prevValue, newStudyLevel]);
        setNewStudyLevel('');
    }

    const studyLevelChange = (e) => {
        setNewStudyLevel(e.target.value);
    }



    //MEDIA SUBMIT
    const mediaSubmit = async (e) => {
        e.preventDefault();
        const renderedStudyLevel = levelName.map((data) => {
            return { level: data }
        })
        let studyLevel = {
            countryLogo: countryLogo,
            countryName: theCountryName,
            countryStudyLevels: renderedStudyLevel
        }

        await baseURL.post('/institute/country/studyLevel/save', studyLevel).then(response => {
            getStudyLevel().then(() => {
                setOpenAddModal(false)
            })
        })
    }


    //GET STUDY LEVEL
    const [studyLevel, setStudyLevel] = useState([]);
    useEffect(() => getStudyLevel(), []);
    const getStudyLevel = async () => {
        const response = await baseURL.get('/institute/country/studyLevel/findAll').then((res) => {
            setStudyLevel(res.data)
        })
    }

    const renderedStudyLevel = studyLevel.map((data, index) => {
        const theStudyLevel = data.countryStudyLevels.map((data,index) => {
            return (
                <span key={index}>{data.level.charAt(0).toUpperCase() + data.level.slice(1)} <br /> </span >
            )
        })
        return (
            <tr key={data.id}>
                <td className="tableCellData">{index + 1}.</td>
                <td className="tableCellData">
                    {data.countryName}
                </td>
                <td className="tableCellData">
                    {theStudyLevel}
                </td>
                <td>
                    <IconButton
                        color="secondary"
                        onClick={() => handleOpenEditModal(data.countryName, data.id)}
                    >
                        <EditIcon fontSize="medium" />
                    </IconButton>
                </td>
                <td>
                    <IconButton color="error">
                        <DeleteIcon
                            fontSize="medium"
                            onClick={() => handleDeleteDialogOpen(data.id)}
                        />
                    </IconButton>
                </td>
            </tr>
        )
    })


    //GET UPDATE STUDY LEVEL
    const getUpdateLevel = async (countryName) => {
        await baseURL.get(`/institute/country/studyLevel/getStudyLevelByCountry/${countryName}`).then((res) => {
            const renderedCountryStudyLevels = res.data.countryStudyLevels.map((data) => {
                return data.level.charAt(0).toUpperCase() + data.level.slice(1);
            })
            setLevelName(renderedCountryStudyLevels);
            setTheCountryName(res.data.countryName);
            setTheNames(renderedCountryStudyLevels);
        })
    }


    //UPDATE STUDY LEVEL
    const mediaUpdate = async (e) => {
        e.preventDefault();
        const renderedStudyLevel = levelName.map((data) => {
            return { level: data }
        })
        let studyLevel = {
            countryLogo: countryLogo,
            countryName: theCountryName,
            countryStudyLevels: renderedStudyLevel
        }
        baseURL.put(`/institute/country/studyLevel/update/${levelID}`, studyLevel).then(response => {
            getStudyLevel().then(() => {
                setOpenEditModal(false)
            })
        })
    }

    // DELETE STUDY LEVEL
    const deleteLevel = (id) => {
        baseURL.put(`/institute/country/studyLevel/delete/${id}`).then(res => {
            getStudyLevel().then(() => {
                setOpenDeleteDialog(false);
            })
        });
    }



    //CLEAR STATES
    const clearStates = () => {
        setTheCountryName('');
        setStudyLevel([]);
        setTheNames([
            'Graduate',
            'Undergraduate',
            'Master'
        ]);
        setLevelName([]);
        getStudyLevel()
    }



    return (
        <>
            <div className="tabPanelHeader">
                <h3>Study Level Table</h3>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    className="addButton"
                    onClick={() => handleOpenAddModal()}
                >
                    Add
                </Button>
            </div>
            <div>
                <table>
                    <thead className="serviceTableHead">
                        <th>SN</th>
                        <th>Country</th>
                        <th>Level</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </thead>
                    <tbody>
                        {renderedStudyLevel}
                    </tbody>
                </table>
            </div>

            <Modal
                open={openAddModal}
                onClose={handleCloseAddModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="updateModal"
            >
                <div className="addNewTeamModalPopUp">
                    <div className="modalHeader">
                        <h3>Add Study Level</h3>
                    </div>
                    <div className="modalContainer">
                        <FormControl className="modalContent" component="form" onSubmit={(e) => mediaSubmit(e)} >
                            <div className="studyLevelContent">
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                    <InputLabel id="countryLabel">Country</InputLabel>
                                    <Select
                                        labelId="countryLabel"
                                        id="countryLabelID"
                                        value={theCountryName}
                                        onChange={handleChange}
                                        label="Country"
                                        name="countryName"
                                        defaultValue=""
                                        required
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {renderedTryCountries}
                                    </Select>
                                </FormControl>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <TextField
                                        label="Add Study Level"
                                        style={{ width: '100%' }}
                                        id="universityName"
                                        variant="standard"
                                        className="textField"
                                        value={newStudyLevel}
                                        onChange={studyLevelChange}
                                    />
                                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={addLevel}>
                                        <AddIcon />
                                    </IconButton>
                                </div>

                                <FormControl sx={{ m: 1, width: '100%' }} variant="standard" InputLabelProps={{ shrink: true }}
                                >
                                    <InputLabel id="demo-multiple-name-label">Study Level</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="studyLevel"
                                        multiple
                                        value={levelName}
                                        onChange={handleChange}
                                        MenuProps={MenuProps}
                                        name="studyLevel"
                                        required
                                    >
                                        {theNames.map((name) => (
                                            <MenuItem
                                                key={`${name}`}
                                                value={`${name}`}
                                                style={getStyles(name, levelName, theme)}
                                            >
                                                {`${name}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="submitContainer">
                                <Button variant="contained" color="error" onClick={clearStates}>
                                    CLEAR
                                </Button>
                                <Button variant="contained" type="submit" >SUBMIT</Button>
                            </div>
                        </FormControl>

                    </div>
                </div>
            </Modal>

            <Modal
                open={openEditModal}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="updateModal"
            >
                <div className="addNewTeamModalPopUp">
                    <div className="modalHeader">
                        <h3>Update Study Level</h3>
                    </div>
                    <div className="modalContainer">
                        <FormControl className="modalContent" component="form" onSubmit={(e) => mediaUpdate(e)}>

                            <div className="studyLevelContent">
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                    <TextField
                                        label="Country Name"
                                        id="theCountry"
                                        name="countryName"
                                        value={theCountryName}
                                        disabled
                                        variant="standard"
                                        className="textField"
                                    />
                                </FormControl>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <TextField
                                        label="Add Study Level"
                                        style={{ width: '100%' }}
                                        id="universityName"
                                        variant="standard"
                                        className="textField"
                                        value={newStudyLevel}
                                        onChange={studyLevelChange}
                                    />
                                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={addLevel}>
                                        <AddIcon />
                                    </IconButton>
                                </div>

                                <FormControl sx={{ m: 1, width: '100%' }} variant="standard" InputLabelProps={{ shrink: true }}
                                >
                                    <InputLabel id="demo-multiple-name-label">Study Level</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="studyLevel"
                                        multiple
                                        value={levelName}
                                        onChange={handleChange}
                                        MenuProps={MenuProps}
                                        name="studyLevel"
                                        required
                                    >
                                        {theNames.map((name) => (
                                            <MenuItem
                                                key={`${name}`}
                                                value={`${name}`}
                                                style={getStyles(name, levelName, theme)}
                                            >
                                                {`${name}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </div>
                            <div className="submitContainer">
                                <Button variant="contained" color="error" onClick={clearStates}>
                                    CLEAR
                                </Button>
                                <Button variant="contained" type="submit">UPDATE</Button>
                            </div>
                        </FormControl>



                    </div>
                </div>
            </Modal>

            <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure want to delete the selected data?"}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={handleDeleteDialogClose}
                        variant="contained"
                        color="primary"
                    >
                        NO
                    </Button>
                    <Button
                        onClick={() => { deleteLevel(levelID) }}
                        variant="contained"
                        color="error"
                        autoFocus
                    >
                        YES
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StudyLevelTable;



import React, { useState, useEffect } from "react";
import "../../../assets/styles/styles.scss";

import { Button, Grid } from "@mui/material";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

//IMPORT BASEURL
import baseURL from '../../../api/api';

import { useHistory } from "react-router-dom";


//MODAL IMPORTS
import Modal from "@mui/material/Modal";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';


import Select from '@mui/material/Select';
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';

import CancelIcon from '@mui/icons-material/Cancel';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



const TopNavBar = () => {


  //ADD MODAL
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const handleOpenAddModal = async () => {
    getCountries();
    setOpenAddModal(true);
  }

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    // clearStates();
  }

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [course, setCourse] = useState('');
  const [country, setCountry] = useState([]);
  const [tryCountryList, setTryCountryList] = useState([{}]);
  const [date, setDate] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => getCountries(), []);
  const getCountries = async () => {
    const response = await baseURL.get(`/institute/country/findAll`).then((res) => {
      const tryCountryNames = res.data.map(({ countryName, logo }) => {
        return (
          { countryName, logo }
        )
      })
      setTryCountryList(tryCountryNames);
      return null;
    })
  }

  const renderedTryCountries = tryCountryList.map((data) => {
    return (
      <MenuItem key={data.countryName} value={data.countryName}>{data.countryName}</MenuItem>
    )
  })


  const onInputChange = e => {

    if (e.target.name === "countrySelect") {
      setCountry(e.target.value);
    }
    if (e.target.id === 'fullName') {
      setFullName(e.target.value);
    }
    if (e.target.id === 'email') {
      setEmail(e.target.value);
    }
    if (e.target.id === 'mobileNumber') {
      setMobileNumber(e.target.value);
    }
    if (e.target.id === 'educationLevel') {
      setEducationLevel(e.target.value);
    }
    if (e.target.id === 'course') {
      setCourse(e.target.value);
    }
    if (e.target.id === 'date') {
      setDate(e.target.value);
    }
    if (e.target.id === 'message') {
      setMessage(e.target.value);
    }
  }

  const mediaSubmit = (e) => {
    e.preventDefault();
    let body = `Full Name:${fullName}\nEmail:${email}\nMobile Number:${mobileNumber}\nEducation Level:${educationLevel}\nCountry:${country}\nDate:${date}\nMessage:${message}`;

    const mailSender = {
      body,
      subject: 'Registration for Appointment'
    }


    baseURL.post('/api/mailService', mailSender).then(response => {
      clearStates();
      getCountries();
      setOpenAddModal(false);
    })
  }

  const clearStates = () => {
    setFullName('');
    setEmail('');
    setMobileNumber('');
    setEducationLevel('');
    setTryCountryList([]);
    setCourse('');
    setCountry('');
    setDate('');
    setMessage('');
  }


  let history = useHistory();
  if (history.location.pathname === '/admin' || history.location.pathname === '/adminpanel') {
    return null
  }


  return (
    <Grid
      container
      className="topNavbar"
      justifyContent={{ xs: "center", lg: 'space-between' }}
      alignItems={{ lg: 'stretch' }}
      direction={{ xs: "row" }}
    >
      <Grid container item xs={12} sm={6} lg="auto" direction={{ lg: "row" }} justifyContent={{ lg: 'center' }} alignItems="stretch" className="topNavBarMetaData">
        <Grid container item sm={12} lg="auto" direction={{ lg: 'row' }} justifyContent={{ lg: 'center' }} alignItems="center">
          <Grid item lg={12}>
            <p>SPEAK WITH US: <a href="tel:01-5551086" style={{ color: 'white' }}> 01-5551086</a></p>
          </Grid>
        </Grid>
        <Grid
          container
          item
          sm={12}
          lg="auto"
          alignItems="center"
          className="emailLink"
        >
          <p className="contactAddress"><a href="mailto:info@northernstar.com.np" style={{ color: 'white' }}>info@northernstar.com.np</a> </p>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={6}
        lg
        direction={{ lg: "row" }}
        justifyContent={{ lg: "flex-end" }}
        rowSpacing={{ xs: 1 }}
        alignItems={{ lg: "center" }}
        style={{ gridColumnGap: "20px" }}
        className="socialMediaContainer"
      >
        {/* <Grid
            container
            item
            sm={12}
            lg="auto"
            justifyContent={{
              xs: "flex-start",
              sm: "flex-end",
              lg: "space-evenly",
            }}
            alignItems={{ xs: "", sm: "center" }}
            className="socialMediaLinks"
          >
            <a href="https://www.facebook.com/Navc-Group-Of-Education-107551401552002" target="_blank" className="socialMediaLink socialLinkFacebook">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#" className="socialMediaLink socialLinkTwitter">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#" className="socialMediaLink socialLinkYoutube">
              <i className="fab fa-youtube"></i>
            </a>
            <a href="https://www.instagram.com/navceducation/" target="_blank" className="socialMediaLink socialLinkInstagram">
              <i className="fab fa-instagram"></i>
            </a>
          </Grid> */}
        <Grid
          container
          item
          sm={12}
          lg="auto"
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
          direction="row"
          alignItems="center"
          className="registrationBtnContainer"
          style={{ padding: '5px 0px' }}
        >
          <Button
            variant="contained"
            className="addButton registrationBtn"
            onClick={handleOpenAddModal}
            size="small"
            style={{ backgroundColor: '#9b1d21' }}
          >
            BOOK AN APPOINTMENT
          </Button>

          {/* <button className="addButton registrationBtn" onClick={handleOpenAddModal} style={{padding:'16px'}}>BOOK AN APPOINTMENT</button> */}
        </Grid>
      </Grid>

      <Modal
        open={openAddModal}
        onClose={handleCloseAddModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="updateModal topNavBarModalContainer"
      >

        <div className="addNewTeamModalPopUp topnavBarModal">
          <div className="appRegistrationTitle">
            <h5>Appointment Registration</h5>
            <div className="cancelModal" onClick={() => handleCloseAddModal()} >
              <CancelIcon />
            </div>
          </div>


          <div className="modalContainer">
            <FormControl className="modalContent" component="form" onSubmit={(e) => mediaSubmit(e)}>
              <div className="updateImagePreview">
                <TextField
                  label="Full Name"
                  style={{ width: '100%' }}
                  id="fullName"
                  value={fullName}
                  onChange={(e) => onInputChange(e)}
                  variant="standard"
                  className="textField"
                  required
                />
                <TextField
                  label="Email"
                  style={{ width: '100%' }}
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => onInputChange(e)}
                  variant="standard"
                  className="textField"
                />
                <TextField
                  label="Mobile Number"
                  style={{ width: '100%' }}
                  type="tel"
                  id="mobileNumber"
                  pattern="[0-9]{10}"
                  required
                  value={mobileNumber}
                  onChange={(e) => onInputChange(e)}
                  variant="standard"
                  className="textField"
                />
                <TextField
                  label="Education Level"
                  style={{ width: '100%' }}
                  id="educationLevel"
                  value={educationLevel}
                  onChange={(e) => onInputChange(e)}
                  variant="standard"
                  className="textField"
                />
                <TextField
                  label="Course"
                  style={{ width: '100%' }}
                  id="course"
                  value={course}
                  onChange={(e) => onInputChange(e)}
                  variant="standard"
                  className="textField"
                />

                <FormControl variant="standard" sx={{ minWidth: 120, width: '100%' }}>
                  <InputLabel id="countryLabel">Your preferred study destination</InputLabel>
                  <Select
                    labelId="countryLabel"
                    id="countryLabelID"
                    // value={country}
                    onChange={(e) => onInputChange(e)}
                    label="Country"
                    name="countrySelect"
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {renderedTryCountries}
                  </Select>
                </FormControl>

                <TextField
                  label="When do you plan to visit?"
                  id="date"
                  type="date"
                  onChange={(e) => onInputChange(e)}
                  value={date || ''}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  className="textField"
                  style={{ width: '100%', marginTop: '15px' }}
                  required
                />
                <TextField
                  id="message"
                  label="Message"
                  onChange={(e) => onInputChange(e)}
                  multiline
                  rows={5}
                  value={message}
                  className="getInTouchFormInput"
                  style={{ width: '100%', marginTop: '15px' }}
                  required
                />
                <div style={{ width: '100%', marginTop: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button variant="contained" type="submit" className="readMoreButton" style={{ margin: 'auto' }}>Send Message</Button>
                </div>
              </div>
            </FormControl>
          </div>
        </div>
      </Modal>
    </Grid>
  );
};

export default TopNavBar;

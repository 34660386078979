import React, { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { FormControl } from "@mui/material";
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//IMPORT BASEURL
import baseURL from '../../api/api';



const theme = createTheme();

export default function SignIn() {

    const [adminEmail, setAdminEmail] = useState('');
    const [adminPass, setAdminPass] = useState('');


    // const verifyToken = () => {
    //     const oldToken = localStorage.getItem("theKey");
    //     if(oldToken){
    //         window.location.href = "/adminpanel";
    //     }
    // }

    // useEffect(() => {
    //     verifyToken()
    // }, [])

    useEffect(() => {
        window.localStorage.clear(); //clear all localstorage
        // window.localStorage.removeItem("theKey"); //remove one item
    })


    const handleChange = (e) => {
        if (e.target.id === 'adminEmail') {
            setAdminEmail(e.target.value)
        }
        if (e.target.id === 'adminPass') {
            setAdminPass(e.target.value)
        }
    }


    const onLoginSubmit = async (e) => {
        e.preventDefault();
        // console.log("submitted");

        const data = {
            email: adminEmail,
            password: adminPass
        }


        await baseURL.post(`/api/auth/signin`, data).then((res) => {
            if (res.status === 200) {
                localStorage.setItem("theKey", res.data.accessToken)
                localStorage.setItem("lastVerified", new Date())
                // window.location.href = "/adminpanel";
                // console.log(res, 'I M RESPONSE')

                window.location.href = "/adminpanel";
                // window.location.href = "http://localhost:3000/panel";
            }

        }).catch((err) => {
            if (err.response.status === 400) {
                alert(err.response.data)
                return;
            }
        }
        );
    };

    const clearStates = () => {
        setAdminEmail('');
        setAdminPass('');
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Admin Log In
                    </Typography>
                    <Box component="form" onSubmit={onLoginSubmit} noValidate sx={{ mt: 1 }} style={{ width: '1000px', maxWidth: '350px' }}>
                        <FormControl fullWidth>
                            <TextField id="adminEmail" focus onChange={handleChange} value={adminEmail} fullWidth label="Email" variant="standard" sx={{ mt: 2 }} style={{ paddingTop: '8px' }} />
                            <TextField id="adminPass" type="password" onChange={handleChange} value={adminPass} fullWidth label="Password" variant="standard" sx={{ mt: 2 }} style={{ paddingTop: '8px' }} />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 4, mb: 2 }}
                            >
                                Log In
                            </Button>
                        </FormControl>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
import React from "react";
import Avatar from "@mui/material/Avatar";
import girlPng from "../../assets/images/girl.png";
import logo from "../../assets/images/NAVC COLOR.png";
import whiteLogo from '../../assets/images/whiteLogo.png';
import { IconButton } from "@mui/material";

//tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

//lists
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

//menu imports
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

//icon imports
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LogoutIcon from "@mui/icons-material/Logout";

//Table imports
import MainCarouselTable from "./MainCarouselTable";
import IntroCarouselTable from "./IntroCarouselTable";
import CountryTable from './CountryTable';
import StudyLevelTable from './StudyLevelTable';
import NewsTable from './NewsTable';
import ServicesTable from "./ServicesTable";
import EventTable from "./EventTable";
import MyTeamsTable from "./MyTeamsTable";
import TestimonialTable from "./TestimonialTable";
import AccreditationsTable from "./AccreditationsTable";
import UniversityTable from './UniversityTable';

const AdminPanel = () => {

  const [openWebsiteMenu, setOpenWebsiteMenu] = React.useState(true);
  const handleWebsiteMenu = () => {
    setOpenWebsiteMenu(!openWebsiteMenu);
  };

  const [openWebsiteHomeMenu, setOpenWebsiteHomeMenu] = React.useState(true);
  const handleWebsiteHomeMenu = () => {
    setOpenWebsiteHomeMenu(!openWebsiteHomeMenu);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setValue1();
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }



  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number,
  };

  const [value1, setValue1] = React.useState(null);
  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
    setValue();
  };

  //trigger menu

  const [menuTrigger, setMenuTrigger] = React.useState(null);
  const openProfileMenu = Boolean(menuTrigger);
  const handleOpenProfileMenu = (event) => {
    setMenuTrigger(event.currentTarget);
  };
  const handleCloseProfileMenu = () => {
    setMenuTrigger(null);
  };


  const handleLogOut = () => {
    localStorage.removeItem("theKey");
    window.location.href = "/admin";
  }

  return (
    <div className="adminPanel">
      <div className="adminHeader">
        <div className="adminLogo">
          <img src={whiteLogo} />
        </div>
        <div className="adminTitle">
          <h1>NORTHERN STAR EDUCATION CONSULTANCY</h1>
        </div>
        <div className="adminProfile">
          <IconButton onClick={handleOpenProfileMenu}>
            <Avatar src={girlPng} />
          </IconButton>
          <Menu
            anchorEl={menuTrigger}
            open={openProfileMenu}
            onClose={handleCloseProfileMenu}
            onClick={handleCloseProfileMenu}
            className="profileMenu"
          >
            <MenuItem className="profileMenuItem">
              <ListItemIcon>
                <VpnKeyIcon fontSize="small" className="profileMenuIcon" />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <MenuItem className="profileMenuItem"  onClick={handleLogOut}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" className="profileMenuIcon" />
              </ListItemIcon>
              Log Out
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="adminPanelSection">
        <div className="adminPanelMenuSection">
          <List sx={{ width: "100%", padding: "0px" }}>
            <ListItemButton onClick={handleWebsiteMenu}>
              <ListItemText primary="WEBSITE" />
              {openWebsiteMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              in={openWebsiteMenu}
              timeout="auto"
              unmountOnExit
              style={{ paddingLeft: "8px" }}
            >
              <List style={{ padding: "0px" }}>
                <ListItemButton style={{ padding: "0px" }}>
                  <List
                    sx={{ width: "100%", padding: "0px" }}
                    className="websiteHomeMenu"
                  >
                    <ListItemButton onClick={handleWebsiteHomeMenu}>
                      <ListItemText primary="HOME" />
                      {openWebsiteHomeMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </List>
                </ListItemButton>
                <Collapse
                  in={openWebsiteHomeMenu}
                  timeout="auto"
                  unmountOnExit
                  style={{ paddingLeft: "8px" }}
                >
                  <List>
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={value}
                      // disabled="true"
                      onChange={handleChange}
                      aria-label="Vertical tabs example"
                      sx={{ borderRight: 1, borderColor: "divider" }}
                      className="MainPageTab"
                    >
                      <Tab label="Main Slider" className="tab" />
                      <Tab label="Intro Section" className="tab" />
                    </Tabs>
                  </List>
                </Collapse>
              </List>

              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value1}
                onChange={handleChange1}
                sx={{ borderRight: 1, borderColor: "divider" }}
                className="websiteAdminMenuII"
              >
                {/* <Tab label="STUDY ABROAD" className="websiteAdminMenuIITab" /> */}
                <Tab label="COUNTRY" className="websiteAdminMenuIITab" />
                <Tab label="STUDY LEVEL" className="websiteAdminMenuIITab" />
                <Tab label="UNIVERSITIES" className="websiteAdminMenuIITab" />
                <Tab label="SERVICES" className="websiteAdminMenuIITab" />
                <Tab label="NEWS" className="websiteAdminMenuIITab" />
                <Tab label="EVENTS" className="websiteAdminMenuIITab" />
                <Tab label="TESTIMONIALS" className="websiteAdminMenuIITab" />
                <Tab label="MY TEAMS" className="websiteAdminMenuIITab" />
                <Tab label="ACCREDITATIONS" className="websiteAdminMenuIITab" />
              </Tabs>
            </Collapse>
          </List>
        </div>
        <div className="adminViewSection">
          <TabPanel value={value} index={0}>
            <MainCarouselTable />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <IntroCarouselTable />
          </TabPanel>
          <TabPanel value={value1} index={0}>
            <CountryTable />
          </TabPanel>
          <TabPanel value={value1} index={1}>
            <StudyLevelTable />
          </TabPanel>
          <TabPanel value={value1} index={2}>
            <UniversityTable />
          </TabPanel>
          <TabPanel value={value1} index={3}>
            <ServicesTable />
          </TabPanel>
          <TabPanel value={value1} index={4}>
            <NewsTable />
          </TabPanel>
          <TabPanel value={value1} index={5}>
            <EventTable />
          </TabPanel>
          <TabPanel value={value1} index={6}>
            <TestimonialTable />
          </TabPanel>
          <TabPanel value={value1} index={7}>
            <MyTeamsTable />
          </TabPanel>
          <TabPanel value={value1} index={8}>
            <AccreditationsTable />
          </TabPanel>

        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
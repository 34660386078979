import React from "react";
import { Route, Redirect } from "react-router-dom";
import jwt from 'jsonwebtoken';
import moment from 'moment';

const verifyToken = () => {
    const oldToken = localStorage.getItem("theKey");
    const lastVerified = localStorage.getItem("lastVerified");
    if(oldToken){ //if token exist
        if(lastVerified){ //if last verified exist
            const lastVerifiedDate = new Date(lastVerified);
            if(moment(lastVerifiedDate).isBefore(moment().subtract(60, 'minutes'))) {
                //if last verified is older than 60 minutes
                // revalidate token
                try {
                    const decoded = jwt.decode(oldToken);
                    const exp = new Date(decoded.exp * 1000);
                    if(moment(exp).isAfter(moment())){
                        //if token is not expired
                        localStorage.setItem("lastVerified", new Date());
                        return true;
                    }
                    return false;
                } catch(e) {
                    return false;
                }
            }
            return true;
        } else {
            // revalidate token
            try {
                const decoded = jwt.decode(oldToken);
                const exp = new Date(decoded.exp * 1000);
                if(moment(exp).isAfter(moment())){
                    //if token is not expired
                    localStorage.setItem("lastVerified", new Date());
                    return true;
                }
                return false;
            } catch(e) {
                return false;
            }
        }
    }
    return false;
};



// console.log(decoded.foo) // bar

// const auth = decoded; //true







const PrivateRoute = ({ component: Component, ...rest }) => {
    // console.log(Component, 'INJURED')
    return (
        < Route
            {...rest}
            render={(props) =>
                verifyToken() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/admin",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />)
}

export default PrivateRoute;

import { Button, Grid } from "@mui/material";
import React from "react";
import AboutUsImage from "../../assets/images/aboutusImg.jpeg";
import directorImg from "../../assets/images/director.png";
import MeetOurTeam from '../../components/common/innerComponents/MeetOurTeam';
import director from '../../assets/images/director1.jpeg';

import DoneIcon from "@mui/icons-material/Done";
import { Link } from "react-router-dom";


const AboutUs = () => {
  return (
    <>
      <Grid container className="aboutUsPage" direction={{ xs: "column" }}>
        <Grid
          container
          item
          xs="auto"
          direction={{ xs: "column", md: "row" }}
          className="aboutUsIntro"
        >
          <Grid
            container
            item
            xs="auto"
            md={6}
            direction={{ xs: "column" }}
            className="aboutUsIntroImage"
          >
            <img src={AboutUsImage} />
          </Grid>
          <Grid
            container
            item
            xs="auto"
            md={6}
            direction={{ xs: "column" }}
            className="aboutUsIntroDescription"
          >
            <Grid
              container
              item
              xs="auto"
              justifyContent={{ xs: "center", md: "flex-start" }}
              alignItems={{ xs: "center", md: "center" }}
              className="aboutUsIntroTitle"
            >
              <h1>ABOUT US</h1>
            </Grid>
            <Grid container item xs="auto" className="aboutUsIntroText">
              <p>
                Welcome to Northern Star Education Consultancy. NSEC is a pioneer in Nepal for overseas education counseling. With almost a decade of expertise, we are mainly specialized in counseling and career guidance for Australia, America, United Kingdom, Canada, Denmark, Japan and China. We stand with the main motive to guide our students to the best possible institutions abroad to enable them to build their future. Using our expertise and vast experience, we can identify the most suitable institutions for each student. Our excellent relation with the institutions helps us to deliver the best services to our students.
              </p>
              <p style={{ marginTop: '10px' }}>
                We also offer the best IELTS, TOFEL and PTE preparation sessions, as well as assistance with the application process, which includes university communication, financial planning, and visa processing.  As our obsession with quality grows with time, our commitment towards our client grows even more which has been a motivating factor to provide best possible service to our clients.
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction={{ xs: "column", md: "row" }}
          justifyContent={{ md: "space-between" }}
          alignItems={{ md: "flex-start" }}
          className="testimonyAndChecklist"
        >
          <Grid
            container
            item
            xs="auto"
            md={6}
            direction={{ xs: "column" }}
            className="aboutUsTestimony"
          >
            <Grid container item xs="auto" className="aboutUsTestimonyTitle">
              <h1>Suchit Phuyal</h1>
            </Grid>
            <Grid container item xs="auto" className="aboutUsTestimonyPara">
              <p>
                The Navc Group Of Education has a long standing reputation of
                being one of the most comprehensive study abroad consultants in
                Nepal. We are situated at downtown Tinkune, Kathmandu.
              </p>
            </Grid>
            <i className="fas fa-quote-left aboutUsQuoteIcon"></i>

          </Grid>

          <Grid
            container
            item
            xs="auto"
            md={5.5}
            direction={{ xs: "column" }}
            className="aboutUsCheckList"
          >
            <h1>OUR MOTTO</h1>
            <h3>Let’s Plan Your Career Together</h3>

            {/* <Grid container item xs="auto" direction={{ xs: "row" }}>
              <Grid
                container
                item
                xs={1}
                justifyContent="flex-start"
                alignItems="center"
                direction={{ xs: "row" }}
              >
                <DoneIcon className="checkListIcon" />
              </Grid>
              <Grid
                container
                item
                xs={11}
                justifyContent="flex-start"
                alignItems="center"
                direction={{ xs: "row" }}
                style={{ paddingLeft: "16px" }}
              >
                <p>Statement of Proposal</p>
              </Grid>
            </Grid>
            <Grid container item xs="auto" direction={{ xs: "row" }}>
              <Grid
                container
                item
                xs={1}
                justifyContent="flex-start"
                alignItems="center"
                direction={{ xs: "row" }}
              >
                <DoneIcon className="checkListIcon" />
              </Grid>
              <Grid
                container
                item
                xs={11}
                justifyContent="flex-start"
                alignItems="center"
                direction={{ xs: "row" }}
                style={{ paddingLeft: "16px" }}
              >
                <p>Work Experienced</p>
              </Grid>
            </Grid>
            <Grid container item xs="auto" direction={{ xs: "row" }}>
              <Grid
                container
                item
                xs={1}
                justifyContent="flex-start"
                alignItems="center"
                direction={{ xs: "row" }}
              >
                <DoneIcon className="checkListIcon" />
              </Grid>
              <Grid
                container
                item
                xs={11}
                justifyContent="flex-start"
                alignItems="center"
                direction={{ xs: "row" }}
                style={{ paddingLeft: "16px" }}
              >
                <p>Recommendation Letter</p>
              </Grid>
            </Grid>
            <Grid container item xs="auto" direction={{ xs: "row" }}>
              <Grid
                container
                item
                xs={1}
                justifyContent="flex-start"
                alignItems="center"
                direction={{ xs: "row" }}
              >
                <DoneIcon className="checkListIcon" />
              </Grid>
              <Grid
                container
                item
                xs={11}
                justifyContent="flex-start"
                alignItems="center"
                direction={{ xs: "row" }}
                style={{ paddingLeft: "16px" }}
              >
                <p>Easy Steps to follow Abroad</p>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs="column"
          direction={{ xs: "column" }}
          className="messageFromDirectorSection"
        >
          <Grid container item xs="auto" className="messageFromDirectorTitleM" id="directorMessage">
            <h1>MESSAGE FROM MD</h1>
          </Grid>
          <Grid
            container
            item
            direction={{ xs: "column", sm: "row", md: "row" }}
          >
            {/* <Grid
              container
              item
              xs={12}
              sm={12}
              md={4}
              lg={5}
              className="messageFromDirectorImageSection"
            >
              <h1 className="messageFromDirectorTitleS">
                MESSAGE FROM THE FOUNDER OF NAVC1
              </h1>
            </Grid> */}
            <Grid container className="directorText">
              <Grid item xs={12} sm={12} md={5.5} lg={5}
              >
                <img src={director} style={{ width: '100%' }} />
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6.5}
                lg={7}
                className="messageFromDirectorText"
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"

              >

                <p className="directorTextPara">
                  Are you tired of searching for education consultancy that honestly understand your dream and desire rather than treat you as a part of business or try to enroll in whatever they have got? Then your wait is over. Welcome to Northern Star Education Consultancy, a place where a team of dedicated consultants with almost a decade of experience will turn your dream and desire of abroad study into reality.
                </p>
                <p className="directorTextPara">
                  A lot of us may not have proper idea about choosing the right destination, a suitable program to study abroad. That’s ok. These days, there are numerous excellent study destinations to select from including Australia, the United States, Canada, the United Kingdom, China and many others. When you're considering moving abroad for additional studies, it's one of the most crucial decisions you'll ever make. We collaborate closely with our partner institutions to not only promote and recruit deserving students for their top universities, but also to offer them with comprehensive in the market over time. While doing so, we constantly keep the best interests of students at the forefront of our operational values, and our sole goal is to show them the best path to their desired future.
                </p>
                <p className="directorTextPara">
                  We'd like to thank our students, well-wishers, and international partner universities for their unrelenting support. We hope to be of good help to anyone in need of our services. Please feel free to take an appointment to come in for a conversation by contacting us.
                </p>
                <p style={{ textAlign: 'right' }}>
                  Piyush Shrestha <br />
                  Managing Director <br />
                  Northern Star Education Pvt.Ltd.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs="auto"
          className="ourServiceBannerContainer"
          direction={{ xs: "column" }}
          justifyContent={{ xs: "space-between" }}
          alignItems={{ xs: "center" }}
          style={{ marginTop: '0px' }}
        >
          <h1>TAKE THE FIRST STEP TO KNOWLEDGE WITH US</h1>
          <h3>
            Not sure what to do? The Abroad Study Counselor will help you figure
            it out
          </h3>
          <Link to={`/contactus`} className="readMoreButton">
            Contact Us
          </Link>
        </Grid>
      </Grid>
      <div className="aboutUsTeam">
        <MeetOurTeam />
      </div>
    </>
  );
};

export default AboutUs;


import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Link,
  useParams
} from "react-router-dom";


//IMPORT BASEURL
import baseURL from '../../api/api';


const Universities = () => {

  //INITIAL STATE
  let { country } = useParams();
  const [myUniversities, setMyUniversities] = useState([]);
  const [countryUniversity, setCountryUniversity] = useState([]);
  const [levelName, setLevelName] = useState([]);
  const [countryParam, setCountryParam] = useState(country);
  const [theCountryName, setTheCountryName] = useState('');



  // GET UNIVERSITIES

  useEffect(() => {
    setCountryParam(country)
  })

  useEffect(() => {
    getUniversities().then(() => {
      getUpdateLevel();
    })
  }, [countryParam])




  const getUniversities = async () => {
    setMyUniversities([]);
    const response = await baseURL.get('/institute/university/findAll');
    const filteredUniversities = response.data.map((data, index) => {
      if (data.country === countryParam) {
        setMyUniversities(prevValue => [...prevValue, data]);
      }
    })
  }





  //GET UPDATE STUDY LEVEL
  const getUpdateLevel = async () => {
    await baseURL.get(`/institute/country/studyLevel/getStudyLevelByCountry/${countryParam}`).then((res) => {
      const renderedCountryStudyLevels = res.data.countryStudyLevels.map((data) => {
        return data.level.charAt(0).toUpperCase() + data.level.slice(1);
      })
      setLevelName(renderedCountryStudyLevels);
      setTheCountryName(res.data.countryName);
    });
  }


  const renderedLevel = levelName.map((studyLevel) => {
    return (
      <div className="graduateSection" key={studyLevel} id={studyLevel}>
        <div className="graduateSectionTitle">
          <h3 >{studyLevel}</h3>
        </div>
        <div className="graduateCardSection">
          {myUniversities.map((data) => {
            if (data.studyLevel === studyLevel) {
              return (
                <div className="graduateCard" key={data.id}>
                  <div className="graduateImgContainer">
                    <img src={data.image} />
                  </div>
                  <div className="graduateCollegeDescription">
                    <div className="graduateCollegeTitle">
                      <h3>{data.name}</h3>
                    </div>
                    <div className="graduateCollegeText">
                      <p className="para-clamping">
                        {data.universityContentHeadings[0].universityContents[0].text}
                      </p>
                    </div>
                    <div className="graduateCollegeSeeMore">
                      <Link to={`/universitydetails/${data.id}`}>
                        See More
                      </Link>
                    </div>
                  </div>
                </div>

              )
            }
          })}

        </div>
      </div>
    )
  })




  // const renderedGraduateUniversities = myUniversities.map((data, index) => {
  //   if (data.country === country && data.studyLevel === 'Graduate') {
  //     return (
  //       <div className="graduateCard" key={data.id}>
  //         <div className="graduateImgContainer">
  //           <img src={data.image} />
  //         </div>
  //         <div className="graduateCollegeDescription">
  //           <div className="graduateCollegeTitle">
  //             <h3>{data.name}</h3>
  //           </div>
  //           <div className="graduateCollegeText">
  //             <p className="para-clamping">
  //               {data.universityContentHeadings[0].universityContents[0].text}
  //             </p>
  //           </div>
  //           <div className="graduateCollegeSeeMore">
  //             <Link to={`/universitydetails/${data.id}`}>
  //               <a href="#">See More</a>
  //             </Link>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   }
  //   return null;
  // })
  // const renderedUnderGraduateUniversities = myUniversities.map((data, index) => {
  //   if (data.country === country && data.studyLevel === 'Undergraduate') {
  //     return (
  //       <div className="graduateCard" key={data.id}>
  //         <div className="graduateImgContainer">
  //           <img src={data.image} />
  //         </div>
  //         <div className="graduateCollegeDescription">
  //           <div className="graduateCollegeTitle">
  //             <h3>{data.name}</h3>
  //           </div>
  //           <div className="graduateCollegeText">
  //             <p className="para-clamping">
  //               {data.universityContentHeadings[0].universityContents[0].text}
  //             </p>
  //           </div>
  //           <div className="graduateCollegeSeeMore">
  //             <Link to={`/universitydetails/${data.id}`}>
  //               <a href="#">See More</a>
  //             </Link>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   }
  //   return null;
  // })
  // const renderedMastersUniversities = myUniversities.map((data, index) => {
  //   if (data.country === country && data.studyLevel === 'Master') {
  //     return (
  //       <div className="graduateCard" key={data.id}>
  //         <div className="graduateImgContainer">
  //           <img src={data.image} />
  //         </div>
  //         <div className="graduateCollegeDescription">
  //           <div className="graduateCollegeTitle">
  //             <h3>{data.name}</h3>
  //           </div>
  //           <div className="graduateCollegeText">
  //             <p className="para-clamping">
  //               {data.universityContentHeadings[0].universityContents[0].text}
  //             </p>
  //           </div>
  //           <div className="graduateCollegeSeeMore">
  //             <Link to={`/universitydetails/${data.id}`}>
  //               <a href="#">See More</a>
  //             </Link>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   }
  //   return null;
  // })


  return (
    <div className="universities">
      <div className="universitiesTitle">
        <h1>Study In {countryParam} </h1>
      </div>
      <div>
        {renderedLevel}
      </div>
    </div>
  );
};

export default Universities;

import { Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";

//IMAGE IMPORTS
import defaultImage from '../../assets/images/defaultImage.png';

//IMPORT BASEURL
import baseURL from '../../api/api';

//ICON IMPORTS
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from '@mui/icons-material/Cancel';

//MODAL IMPORTS
import Modal from "@mui/material/Modal";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const ServicesTable = () => {
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleOpenEditModal = (id) => {
    setServiceID(id);
    setOpenEditModal(true);
    getUpdateService(id);
  }
  const handleCloseEditModal = () =>
    setOpenEditModal(false);

  //ADD MODAL
  const [openAddModal, setOpenAddModal] =
    React.useState(false);
  const handleOpenAddModal = () => {
    clearStates();
    setOpenAddModal(true);
  }
  const handleCloseAddModal = () =>
    setOpenAddModal(false);

  //DELETE DIALOG 
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleDeleteDialogOpen = (id) => {
    setServiceID(id);
    setOpenDeleteDialog(true);
  };
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const [image, setImage] = useState(defaultImage);
  const [file, setFile] = useState('');
  const [heading, setHeading] = useState('');

  const onImageChange = (e) => {
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]))
  };

  const onInputChange = (e) => {
    if (e.target.id === 'serviceTitle') {
      setHeading(e.target.value);
    }
  }


  //GET SERVICES DATA
  const [myService, setMyService] = useState([]);
  const [serviceID, setServiceID] = useState('');
  useEffect(() => getServices(), []);

  const getServices = async () => {
    const response = await baseURL.get('/institute/ourService/findAll');
    setMyService(response.data);
  }

  //DYNAMIC INPUT ADDITION
  const [inputList, setInputList] = useState([{ contentType: 'para', text: "" }]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    if (e.target.id === 'addPara') {
      setInputList([...inputList, { contentType: 'para', text: "" }]);
    }

    if (e.target.id === 'addTitle') {
      setInputList([...inputList, { contentType: 'title', text: "" }]);
    }
    if (e.target.id === 'addList') {
      setInputList([...inputList, { contentType: 'list', text: "" }]);
    }
  };


  // SERVICE MEDIA SUBMIT
  const mediaSubmit = async (e) => {
    e.preventDefault();

    let ourService = {
      image: '',
      mainHeading: heading,
      ourServiceContentHeadings: [
        {
          heading: "Interesting Facts About Australia",
          ourServiceContents: inputList
        }
      ]
    }
    let data = new FormData();
    data.append('file', file);
    data.append('ourService', JSON.stringify(ourService));
    await baseURL.post('/institute/ourService/save', data).then(response => {
      getServices().then(() => {
        setOpenAddModal(false);
      })
    }).catch((err) => console.log(err, 'err err '))

  }


  //GET NEWS BY ID
  const getUpdateService = async (id) => {
    await baseURL.get(`/institute/ourService/findById/${id}`).then((res) => {
      setHeading(res.data.mainHeading);
      setImage(res.data.image);

      const updateContents = res.data.ourServiceContentHeadings[0].ourServiceContents.map(({ text, contentType }) => {
        return { text, contentType };
      });
      setInputList(updateContents)
    })
  }


  // DELETE NEWS
  const deleteService = (id) => {
    baseURL.delete(`/institute/ourService/deleteById/${id}`).then(res => {
      getServices().then(() => {
        setOpenDeleteDialog(false);
      })
    });
  }


  // UPDATE NEWS
  const mediaUpdate = (e) => {
    e.preventDefault();
    let ourService = {
      image: '',
      mainHeading: heading,
      ourServiceContentHeadings: [
        {
          heading: "Interesting Facts About Australia",
          ourServiceContents: inputList
        }
      ]
    }
    let data = new FormData();
    data.append('file', file);
    data.append('ourService', JSON.stringify(ourService));

    baseURL.put(`/institute/ourService/update/${serviceID}`, data).then(response => {
      getServices().then(() => {
        setOpenEditModal(false);
      })
    }).catch((err) => console.log(err, 'err err '))
  }


  //CLEAR STATES
  const clearStates = () => {
    setImage(defaultImage);
    setFile('');
    setHeading('');
    setInputList([{ contentType: 'para', text: "" }])
  }



  //SERVICE RENDERED
  const renderedService = myService.map((data, index) => {
    return (
      <tr key={data.id}>
        <td className="tableCellData">{index + 1}.</td>
        <td className="imagePreviewContainer">
          <div className="imagePreviewBox">
            <img src={data.image} className="portImagePreview" />
          </div>
        </td>
        <td className="tableCellData" title={data.mainHeading} >
          <span className="singleLineClamp">
            {data.mainHeading}
          </span>
        </td>
        <td className="tableCellData" title={data.ourServiceContentHeadings[0].ourServiceContents[0].text}>
          <span className="singleLineClamp">
            {data.ourServiceContentHeadings[0].ourServiceContents[0].text}
          </span>
        </td>
        <td>
          <IconButton
            color="secondary"
            onClick={() => handleOpenEditModal(data.id)}
          >
            <EditIcon fontSize="medium" />
          </IconButton>
        </td>
        <td>
          <IconButton color="error">
            <DeleteIcon
              fontSize="medium"
              onClick={() => handleDeleteDialogOpen(data.id)}
            />
          </IconButton>
        </td>
      </tr>
    )
  })




  return (
    <>
      <div className="tabPanelHeader">
        <h3>Services Table</h3>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
          className="addButton"
        >
          Add
        </Button>
      </div>
      <div>
        <table>
          <thead className="serviceTableHead">
            <th>SN</th>
            <th>Service Image</th>
            <th>Service Title</th>
            <th>Service Description</th>
            <th>Edit</th>
            <th>Delete</th>
          </thead>
          <tbody>
            {renderedService}
          </tbody>
        </table>

        <Modal
          open={openEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="updateModal"
        >
          <div className="addNewTeamModalPopUp">
            <div className="modalHeader">
              <h3>Update Service</h3>
            </div>
            <div className="modalContainer">
              <FormControl className="modalContent" component="form" onSubmit={(e) => mediaUpdate(e)} >
                <div className="updateImagePreview">
                  <img src={image} />
                </div>
                <div className="textFieldContainer">
                  <TextField
                    type="file"
                    onChange={onImageChange}
                    variant="standard"
                    className="textField"
                  />

                  <TextField
                    label="Service Title"
                    value={heading}
                    variant="standard"
                    className="textField"
                    id="serviceTitle"
                    onChange={onInputChange}
                    required
                  />
                  {inputList.map((x, i) => {
                    if (x.contentType === 'para') {
                      return (
                        <div className="box" key={i}>
                          <TextField
                            label="Paragraph"
                            name="text"
                            style={{ width: '100%' }}
                            value={x.text}
                            onChange={e => handleInputChange(e, i)}
                            multiline
                            rows={4}
                          />

                          <div className="btn-box">
                            {inputList.length !== 1 &&
                              <>
                                <IconButton color="error">
                                  <CancelIcon
                                    fontSize="medium"
                                    onClick={() => handleRemoveClick(i)}
                                  />
                                </IconButton>
                              </>
                            }
                            {inputList.length - 1 === i &&
                              <>
                                <div className="CMSkeys">
                                  <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                  <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                  <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      );

                    }
                    if (x.contentType === 'title') {
                      return (
                        <div className="box" key={i}>

                          <TextField
                            label="Title"
                            style={{ width: '100%' }}
                            onChange={e => handleInputChange(e, i)}

                            value={x.text}
                            name="text"
                            variant="standard"
                            className="textField"
                          />

                          <div className="btn-box">
                            {inputList.length !== 1 &&
                              <IconButton color="error">
                                <CancelIcon
                                  fontSize="medium"
                                  onClick={() => handleRemoveClick(i)}
                                />
                              </IconButton>
                            }
                            {inputList.length - 1 === i &&
                              <>
                                <div className="CMSkeys">
                                  <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                  <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                  <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      );

                    }
                    if (x.contentType === 'list') {
                      return (
                        <div className="box" key={i}>

                          <TextField
                            label="List"
                            style={{ width: '100%' }}
                            onChange={e => handleInputChange(e, i)}

                            name="text"
                            value={x.text}
                            variant="standard"
                            className="textField"
                          />

                          <div className="btn-box">
                            {inputList.length !== 1 &&
                              <IconButton color="error">
                                <CancelIcon
                                  fontSize="medium"
                                  onClick={() => handleRemoveClick(i)}
                                />
                              </IconButton>
                            }
                            {inputList.length - 1 === i &&
                              <>
                                <div className="CMSkeys">
                                  <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                  <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                  <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="submitContainer">
                  <Button variant="contained" color="error" onClick={clearStates}>
                    CLEAR
                  </Button>
                  <Button variant="contained" type="submit">UPDATE</Button>
                </div>
              </FormControl>
            </div>
          </div>
        </Modal >

        <Modal
          open={openAddModal}
          onClose={handleCloseAddModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="updateModal"
        >
          <div className="addNewTeamModalPopUp">
            <div className="modalHeader">
              <h3>Add Service</h3>
            </div>
            <div className="modalContainer">
              <FormControl className="modalContent" component="form" onSubmit={(e) => mediaSubmit(e)} >
                <div className="updateImagePreview">
                  <img src={image} />
                </div>
                <div className="textFieldContainer">
                  <TextField
                    type="file"
                    onChange={onImageChange}

                    variant="standard"
                    className="textField"
                    required
                  />

                  <TextField
                    label="Service Title"
                    style={{ width: '100%' }}
                    onChange={onInputChange}
                    id="serviceTitle"
                    value={heading}
                    variant="standard"
                    className="textField"
                    required

                  />
                  {inputList.map((x, i) => {
                    if (x.contentType === 'para') {
                      return (
                        <div className="box" key={i}>
                          <TextField
                            label="Paragraph"
                            name="text"
                            style={{ width: '100%' }}
                            value={x.text}
                            onChange={e => handleInputChange(e, i)}
                            multiline
                            rows={4}
                          />

                          <div className="btn-box">
                            {inputList.length !== 1 &&

                              <IconButton color="error">
                                <CancelIcon
                                  fontSize="medium"
                                  onClick={() => handleRemoveClick(i)}
                                />
                              </IconButton>
                            }
                            {inputList.length - 1 === i &&
                              <>
                                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                  <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                  <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      );
                    }
                    if (x.contentType === 'title') {
                      return (
                        <div className="box">
                          <TextField
                            label="Sub Title"
                            name='text'
                            style={{ width: '100%' }}
                            value={x.text}
                            onChange={e => handleInputChange(e, i)}
                            variant="standard"
                            className="textField"
                          />

                          <div className="btn-box">
                            {inputList.length !== 1 &&

                              <IconButton color="error">
                                <CancelIcon
                                  fontSize="medium"
                                  onClick={() => handleRemoveClick(i)}
                                />
                              </IconButton>
                            }
                            {inputList.length - 1 === i &&
                              <>
                                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                  <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                  <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      );
                    }
                    if (x.contentType === 'list') {
                      return (
                        <div className="box">
                          <TextField
                            label="List"
                            name='text'
                            style={{ width: '100%' }}
                            value={x.text}
                            onChange={e => handleInputChange(e, i)}
                            variant="standard"
                            className="textField"
                          />

                          <div className="btn-box">
                            {inputList.length !== 1 &&

                              <IconButton color="error">
                                <CancelIcon
                                  fontSize="medium"
                                  onClick={() => handleRemoveClick(i)}
                                />
                              </IconButton>
                            }
                            {inputList.length - 1 === i &&
                              <>
                                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                  <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                  <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      );
                    }
                  })}


                </div>
                <div className="submitContainer">
                  <Button variant="contained" color="error" onClick={clearStates} >
                    CLEAR
                  </Button>
                  <Button variant="contained" type="submit">SUBMIT</Button>
                </div>
              </FormControl>
            </div>
          </div>
        </Modal>

        <Dialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure want to delete the selected data?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleDeleteDialogClose}
              variant="contained"
              color="primary"
            >
              NO
            </Button>
            <Button
              onClick={() => { deleteService(serviceID) }}
              variant="contained"
              color="error"
              autoFocus
            >
              YES
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    </>
  );
};

export default ServicesTable;



  // {
  //       "image": "string",
  //       "mainHeading": "About Australia",
  //       "ourServiceContentHeadings": [
  //         {
  //           "heading": "Interesting Facts About Australia",
  //           "ourServiceContents": [
  //             {
  //               "contentType": "para",
  //               "text": "para 1"
  //             },
  //             {
  //               "contentType": "points",
  //               "text": "point 1"
  //             },
  //             {
  //               "contentType": "points",
  //               "text": "point 2"
  //             }
  //           ]
  //         }
  //       ]
  // }




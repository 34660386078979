import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import slide1 from "../../assets/images/slide1.png";
import slide2 from "../../assets/images/slide2.png";
import slide3 from "../../assets/images/slide3.png";
import slide4 from "../../assets/images/slide4.png";

//IMPORT BASEURL
import baseURL from '../../api/api';



const RecommendedPlace = () => {
  var settings = {
    dots: true,
    infinite:true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };



  //GET COUNTRIES DATA

  const [countries, setCountries] = useState([]);
  useEffect(() => getCountries(), []);

  const getCountries = async () => {
    const response = await baseURL.get('/institute/country/findAll');
    setCountries(response.data);
  }

  const renderedCountries = countries.map((data) => {
    return (
      <div className="destinationImageContainer" key={data.id}>
        <Link to={`/country/${data.countryName}`}>
          <img src={data.image} key={data.id} />
        </Link>
        <h5 style={{ textAlign: 'center', color: 'white' }}>STUDY IN {data.countryName.toUpperCase()}</h5>
        {/* <div className="destinationName">
          {data.countryName}
        </div> */}
      </div>
    )

  })

  return (


    <Grid container direction={{ xs: "row" }} className="recommendations" >
      <Grid xs={12} item className="recommendationsTitle" >
        <h5 className="recommendationsHeading">
          CHOOSE THE BEST PLACE FOR YOU
        </h5>
        <h5 className="recommendationsBanner" >
          WE RECOMMEND YOU THE BEST COLLEGE IN BEST DESTINATION TO BUILD YOUR
          CAREER.
        </h5>
      </Grid>
      <Grid xs={12} item>
        <Slider {...settings} className="recommendationsSlider">
          {renderedCountries}
        </Slider>
      </Grid>
    </Grid>
  );
};

export default RecommendedPlace;

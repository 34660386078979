import React, { useState, useEffect } from "react";
import Slider from "react-slick";

//IMPORT BASEURL
import baseURL from '../../api/api';


const TopCarousel = () => {
  const [mainSlider, setMainSlider] = useState([]);
  useEffect(() => getTopSlider(), []);

  const getTopSlider = async () => {
    const response = await baseURL.get('/institute/home/mainSlider/findAll');
    setMainSlider(response.data)
  }

  const renderedMainSlider = mainSlider.map(({ id, image }) => {
    return (
      <img src={image} key={id} style={{ width: '100%',height:'100%',border:'2px solid red' }} />
    )
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    adaptiveHeight: true,
  };

  return (
    <Slider {...settings} style={{ borderTop: '3px solid #9b1d21', borderBottom: '3px solid #9b1d21' }}>
      {renderedMainSlider}
    </Slider>
  );
};

export default TopCarousel;


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";



import baseURL from '../../api/api';

const OurServices = () => {

  useEffect(() => {
    getService();
  }, [])

  const [service, setService] = useState([]);
  const getService = async () => {
    const response = await baseURL.get('/institute/ourService/findAll');

    const { data: responseData = {} } = await baseURL.get('/institute/ourService/findAll');
    let modifiedData = [];
    responseData.map((data) => {
      let { mainHeading } = data;
      mainHeading = mainHeading.trim().toLowerCase();
      if (mainHeading === "ielts") {
        modifiedData.splice(0, 0, data);
      } else if (mainHeading === "pte") {
        let [{ mainHeading: mh }] = modifiedData;
        mh = mh.trim().toLowerCase();
        if (mh === "ielts") {
          modifiedData.splice(1, 0, data);
        } else {
          modifiedData.splice(0, 0, data);
        }
      } else {
        modifiedData.push(data);
      }
    });
    setService(modifiedData);
  }

  const renderedService = service.map((data) => {
    return (
      <div className="ourServiceCard" key={data.id}>
        <div className="ourServiceImageContainer">
          <img src={data.image} style={{ width: '100%', margin: 'auto' }} />
        </div>
        <div className="ourServiceHeader">
          {/* <h3 className="para-clamping">
            {data.mainHeading}
          </h3> */}
          <h5 className="serviceHeader para-clamping">{data.mainHeading}</h5>
        </div>
        <div className="ourServiceContent">
          <p className="para-clamping">
            {data.ourServiceContentHeadings[0].ourServiceContents[0].text}
          </p>
        </div>
        <div className="serviceSeeMore">
          <Link to={`/service/${data.id}`} className="getMoreDetailsBtn">
            Read More
          </Link>
        </div>
      </div>
    )
  })

  return (
    <div className="ourServices">
      <div className="ourServicesTitle" >
        <h1 style={{ textAlign: "center" }}>OUR SERVICE</h1>
      </div>
      <div className="ourServicesCards">
        {renderedService}
      </div>
    </div>
  );
};

export default OurServices;

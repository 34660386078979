import { Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";

//IMAGE IMPORTS
import defaultImage from '../../assets/images/defaultImage.png';

//IMPORT BASEURL
import baseURL from '../../api/api';

//ICON IMPORTS
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';

//MODAL IMPORTS
import Modal from "@mui/material/Modal";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const NewsTable = () => {

  //ADD MODAL
  const [openAddModal, setOpenAddModal] =
    React.useState(false);
  const handleOpenAddModal = () => {
    clearStates();
    setOpenAddModal(true);

  }
  const handleCloseAddModal = () =>
    setOpenAddModal(false);

  //DELETE DIALOG 
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleDeleteDialogOpen = (id) => {
    setNewsID(id);
    setOpenDeleteDialog(true);
  };
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  //EDIT MODAL
  const [openEditModal, setOpenEditModal] =
    React.useState(false);

  const handleOpenEditModal = (id) => {
    setNewsID(id);
    setOpenEditModal(true);
    getUpdateNews(id);
  }
  const handleCloseEditModal = () => setOpenEditModal(false);




  //INITIAL CREATE STATE
  const [newsID, setNewsID] = useState();
  const [image, setImage] = useState(defaultImage);
  const [file, setFile] = useState('');
  const [date, setDate] = useState('');
  const [contentType, setContentType] = useState('whatever');
  const [heading, setHeading] = useState();

  const onImageChange = (e) => {
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]))
  };

  const onInputChange = (e) => {
    e.preventDefault();
    if (e.target.id === 'newsDate') {
      setDate(e.target.value)
    }
    if (e.target.id === 'newsHeading') {
      setHeading(e.target.value)
    }
  }


  //DYNAMIC INPUT ADDITION
  const [inputList, setInputList] = useState([{ text: "" }]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { text: "" }]);
  };

  const mediaSubmit = async (e) => {
    e.preventDefault();
    let news = {
      date: date,
      image: '',
      newsContentHeadings: [
        {
          contentType: contentType,
          heading: heading,
          newsContents: inputList
        }
      ]
    };

    let data = new FormData();
    data.append('file', file);
    data.append('news', JSON.stringify(news));
    await baseURL.post('/institute/home/news/save', data).then(response => {
      getNews().then(() => {
        setOpenAddModal(false);
      })
    }).catch((err) => console.log(err, 'err err '))
  }

  //GET MY TEAMS DATA
  const [ourNews, setOurNews] = useState([]);
  useEffect(() => {
    getNews()
  }, []);

  // DELETE NEWS
  const deleteNews = (id) => {
    baseURL.delete(`/institute/home/news/deleteById/${id}`).then(res => {
      getNews().then(() => {
        setOpenDeleteDialog(false);
      })
    });
  }

  //GET NEWS
  const getNews = async () => {
    const response = await baseURL.get('/institute/home/news/findAll').then((res) => {
      setOurNews(res.data);
    })
  }

  const renderedNews = ourNews.map(({ id, image, date, newsContentHeadings }, index) => {
    const theDate = new Date(date);
    let theMonth = theDate.toLocaleString('en-us', { month: 'long' });
    let theWeekDay = theDate.toLocaleDateString('en-US', { weekday: 'long' })
    let theDay = theDate.getDate();
    let theYear = theDate.getFullYear();

    const renderedNewsContentHeadings = newsContentHeadings.map(({ id, contentType, heading, newsContents }) => {
      const renderedNewsContents = newsContents.map(({ text }) => {
        return text;
      })
      return [heading, renderedNewsContents]
    }
    )
    const renderedPara = renderedNewsContentHeadings[0][1].map((data, index) => {

      return <span key={index}>{data}</span>
    })

    return (
      <tr key={id}>
        <td>{index + 1}.</td>
        <td className="imagePreviewContainer">
          <div className="imagePreviewBox">
            <img src={image} className="portImagePreview" />
          </div>
        </td>
        <td title={renderedNewsContentHeadings[0][0]}><span className="singleLineClamp"> {renderedNewsContentHeadings[0][0]} </span></td>
        <td style={{ fontSize: '14px' }}>{theWeekDay}, <br /> {theMonth} {theDay}, {theYear}</td>
        <td style={{ paddingLeft: '10px' }} title={renderedPara}>
          <span className="singleLineClamp">{renderedPara}</span>
        </td>
        <td>
          <IconButton color="secondary" onClick={() => handleOpenEditModal(id)}>
            <EditIcon fontSize="medium" />
          </IconButton>
        </td>
        <td>
          <IconButton color="error">
            <DeleteIcon
              fontSize="medium"
              onClick={() => handleDeleteDialogOpen(id)}
            />
          </IconButton>
        </td>
      </tr>
    )
  });

  //GET NEWS BY ID
  const getUpdateNews = async (id) => {
    await baseURL.get(`/institute/home/news/findById/${id}`).then((res) => {

      setHeading(res.data.newsContentHeadings[0].heading)
      setImage(res.data.image);
      setDate(res.data.date);
      setContentType('whatever');
      const updateNewsContents = res.data.newsContentHeadings[0].newsContents.map(({ text }) => {
        return { text }
      });
      setInputList(updateNewsContents)
    })
  }

  //UPDATE NEWS
  const mediaUpdate = (e) => {
    e.preventDefault();
    let news = {
      date: date,
      image: '',
      newsContentHeadings: [
        {
          contentType: contentType,
          heading: heading,
          newsContents: inputList
        }
      ]
    };

    let data = new FormData();
    data.append('file', file);
    data.append('news', JSON.stringify(news));

    baseURL.put(`/institute/home/news/update/${newsID}`, data).then(response => {
      getNews().then(() => {
        setOpenEditModal(false);
      })
    }).catch((err) => console.log(err, 'err err '))
  }


  //CLEAR STATES
  const clearStates = () => {
    setNewsID();
    setImage(defaultImage);
    setFile('');
    setDate('');
    setContentType('whatever');
    setHeading('');
    setInputList([{ text: "" }]);
  }

  return (
    <>
      <div className="tabPanelHeader">
        <h3>News Table</h3>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
          className="addButton"
        >
          Add
        </Button>
      </div>
      <div>
        <table>
          <thead className="serviceTableHead">
            <th>SN</th>
            <th>News Image</th>
            <th>News Title</th>
            <th>News Date</th>
            <th>News Description</th>
            <th>Edit</th>
            <th>Delete</th>
          </thead>
          <tbody>
            {renderedNews}
          </tbody>
        </table>

        <Modal
          open={openEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="updateModal"
          scroll="body"
        >
          <div className="addNewTeamModalPopUp">
            <div className="modalHeader">
              <h3>Update News</h3>
            </div>
            <div className="modalContainer">
              <FormControl className="modalContent" component="form" onSubmit={(e) => mediaUpdate(e)}>
                <div className="updateImagePreview">
                  <img src={image} />
                </div>
                <div className="textFieldContainer">
                  <TextField
                    type="file"
                    onChange={onImageChange}
                    variant="standard"
                    className="textField"
                  />
                  <TextField
                    label="News Date"
                    id="newsDate"
                    type="date"
                    value={date}
                    onChange={onInputChange}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    className="textField"
                    required
                  />

                  <TextField
                    label="News Title"
                    value={heading}
                    id="newsHeading"
                    onChange={onInputChange}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    className="textField"
                    required
                  />

                  {inputList.map((x, i) => {
                    return (
                      <div className="box" key={i}>
                        <TextField
                          label="News Paragraph"
                          name="text"
                          style={{ width: '100%' }}
                          id="testimonyTestimony"
                          value={x.text}
                          onChange={e => handleInputChange(e, i)}
                          multiline
                          rows={4}
                        />

                        <div className="btn-box">
                          {inputList.length !== 1 &&
                            <IconButton color="error">
                              <CancelIcon
                                fontSize="medium"
                                onClick={() => handleRemoveClick(i)}
                              />
                            </IconButton>
                          }
                          {inputList.length - 1 === i &&
                            <IconButton color="primary">
                              <AddCircleIcon
                                fontSize="medium"
                                onClick={() => handleAddClick(i)}
                              />
                            </IconButton>}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="submitContainer">
                  <Button variant="contained" color="error" onClick={clearStates}>
                    CLEAR
                  </Button>
                  <Button variant="contained" type="submit">UPDATE</Button>
                </div>
              </FormControl>
            </div>
          </div>
        </Modal>

        <Modal
          open={openAddModal}
          onClose={handleCloseAddModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="updateModal"
        >
          <div className="addNewTeamModalPopUp">
            <div className="modalHeader">
              <h3>Add News</h3>
            </div>
            <div className="modalContainer">
              <FormControl className="modalContent" component="form" onSubmit={(e) => mediaSubmit(e)}>
                <div className="updateImagePreview">
                  <img src={image} />
                </div>
                <div className="textFieldContainer">
                  <TextField
                    type="file"
                    onChange={onImageChange}
                    variant="standard"
                    className="textField"
                    required
                  />
                  <TextField
                    label="News Title"
                    value={heading}
                    id="newsHeading"
                    onChange={onInputChange}
                    variant="standard"
                    className="textField"
                    required
                  />
                  <TextField
                    label="News Date"
                    id="newsDate"
                    type="date"
                    value={date}
                    onChange={onInputChange}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    className="textField"
                    required
                  />
                  {inputList.map((x, i) => {
                    return (
                      <div className="box" key={i}>
                        <TextField
                          label="News Paragraph"
                          name="text"
                          style={{ width: '100%' }}
                          id="testimonyTestimony"
                          value={x.text}
                          onChange={e => handleInputChange(e, i)}
                          multiline
                          rows={4}
                        />

                        <div className="btn-box">
                          {inputList.length !== 1 &&
                            <IconButton color="error">
                              <CancelIcon
                                fontSize="medium"
                                onClick={() => handleRemoveClick(i)}
                              />
                            </IconButton>
                          }
                          {inputList.length - 1 === i &&
                            <IconButton color="primary">
                              <AddCircleIcon
                                fontSize="medium"
                                onClick={() => handleAddClick(i)}
                              />
                            </IconButton>}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="submitContainer">
                  <Button variant="contained" color="error">
                    CLEAR
                  </Button>
                  <Button variant="contained" type="submit">SUBMIT</Button>
                </div>
              </FormControl>
            </div>
          </div>
        </Modal>

        <Dialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure want to delete the selected data?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleDeleteDialogClose}
              variant="contained"
              color="primary"
            >
              NO
            </Button>
            <Button
              onClick={() => { deleteNews(newsID) }}
              variant="contained"
              color="error"
              autoFocus
            >
              YES
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default NewsTable;



// {"date":"2021-12-20",
// "image":"",
// "newsContentHeadings":[{"contentType":"whatever",
//                         "heading":"title",
//                         "newsContents":[{"text":"PARA1"},
//                                         {"text":"PARA2"},
//                                         {"text":"PARA3"}]}]}

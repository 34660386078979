
import baseURL from '../../../api/api';
import React, { useState, useEffect } from "react";
import { Grid } from '@mui/material';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";




const UniversityDetails = () => {
    let { id } = useParams();

    const [university, setUniversity] = useState([]);
    const [heading, setHeading] = useState('');

    useEffect(() => getUniversity(), []);

    const getUniversity = async () => {
        await baseURL.get(`/institute/university/findById/${id}`).then((res) => {
            setUniversity([res.data]);
            setHeading(res.data.name);

        })
    }

    const renderedUniversity = university.map((data) => {


        const renderedPara = data.universityContentHeadings[0].universityContents.map(({ id, contentType, text }) => {
            if (contentType == 'title') {
                return <h3 key={id} style={{ marginTop: '16px', textAlign: 'justify' }}>{text} <br /> </h3>
            }
            if (contentType === 'para') {
                return <p key={id} style={{ textAlign: 'justify' }}>{text} <br /> </p>
            }
            if (contentType === 'list') {
                return <li key={id} style={{ marginLeft: '16px' }}>{text}</li>
            }
            return null
        })


        return (

            <Grid key={id}>
                <Grid container item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1>Study In {heading}</h1>
                </Grid>
                <Grid container item>
                    <img src={data.image} style={{ width: '100%' }} />
                </Grid>
                <Grid container item style={{ display: 'flex', flexDirection: 'column' }}>
                    {renderedPara}
                </Grid>
            </Grid>
        )
    })

    return (
        <Grid container item className="serviceDetail">
            {renderedUniversity}
        </Grid>

    )
}

export default UniversityDetails;
import { AppBar, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../../../assets/styles/styles.scss";
import { Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HashLink } from 'react-router-hash-link';
import { useHistory } from "react-router-dom";


//image importing
import logo from "../../../assets/images/logo.jpg";
import token from '../../../assets/images/token.png';
import whiteLogo from '../../../assets/images/whiteLogo.png';

import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';



//IMPORT BASEURL
import baseURL from '../../../api/api';

import {
  BrowserRouter as Router,
  Link,
  NavLink,
} from "react-router-dom";


//POPOVER
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';




const Header = () => {
  const smallerDeviceMatch = useMediaQuery("(max-width:1023px)");
  const [openCollapseMenu, setOpenCollapseMenu] = useState(false);
  const [openStudyAbroad, setOpenStudyAbroad] = React.useState(false);
  const [openService, setOpenService] = useState(false);

  const handleCollapse = () => {
    setOpenCollapseMenu((prev) => !prev);
    setOpenStudyAbroad(false);
    setOpenService(false);
  };


  //POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenStudy = () => {
    setOpenStudyAbroad((prev) => !prev);
    setOpenService(false);


  };

  const handleOpenService = () => {
    setOpenService((prev) => !prev);
    setOpenStudyAbroad(false);
  }

  //DESKTOP VIEW STUDY ABROAD MENU
  const [studyLevel, setStudyLevel] = useState([]);
  const [service, setService] = useState([]);
  const [openedPortal, setOpenedPortal] = useState([]);

  useEffect(() => getStudyLevel(), []);
  const getStudyLevel = async () => {
    const response = await baseURL.get('/institute/country/studyLevel/findAll').then((res, index) => {
      res.data.map((data, index) => {

        //MAKADOX
        setOpenedPortal(openedPortal => [...openedPortal, { countryID: index, countryName: data.countryName, openPortal: false }])
        return (
          <p key={data.id}></p>
        )
      })
      setStudyLevel(res.data)
    });
  }

  useEffect(() => {
    getService();
  }, [])

  const getService = async () => {

    const { data: responseData = {} } = await baseURL.get('/institute/ourService/findAll');
    let modifiedData = [];
    responseData.map((data) => {
      let { mainHeading } = data;
      mainHeading = mainHeading.trim().toLowerCase();
      if (mainHeading === "ielts") {
        modifiedData.splice(0, 0, data);
      } else if (mainHeading === "pte") {
        let [{ mainHeading: mh }] = modifiedData;
        mh = mh.trim().toLowerCase();
        if (mh === "ielts") {
          modifiedData.splice(1, 0, data);
        } else {
          modifiedData.splice(0, 0, data);
        }
      } else {
        modifiedData.push(data);
      }
    });
    setService(modifiedData);
  }


  const renderService = service.map((data, index, arr) => {
    return (
      <ListItemButton className="countrySelection" key={data.id} >
        <Link to={`/service/${data.id}`} style={{ width: '100%' }}>{data.mainHeading.toUpperCase()}</Link>
      </ListItemButton>
    )
  })


  const renderStudyLevel = studyLevel.map((data) => {
    const theStudyLevel = data.countryStudyLevels.map(({ id, level }) => {
      return (
        <>
          <HashLink key={id} smooth to={`/universities/${data.countryName}#${level}`} className="countrySelection"
          >
            <ListItem >
              <ListItemText style={{ width: '100%' }} className="countryStudyLevelDrop">{level.charAt(0).toUpperCase() + level.slice(1)}</ListItemText>
            </ListItem>
          </HashLink>
          <Divider />
        </>
      )
    })
    return (
      <ListItemButton className="countrySelection" key={data.id} >
        <ListItemAvatar>
          <Avatar src={data.countryLogo} />
        </ListItemAvatar>
        <HashLink smooth to={`/universities/${data.countryName}`}>Study In {data.countryName}</HashLink>
        <List className="studyLevelsContainer" style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          {theStudyLevel}
        </List>
      </ListItemButton>
    )
  })

  //MOBILE VIEW STUDY ABROAD
  const handleOpenStudyCountry = (countryName, indexMan) => {
    openedPortal.forEach((element, index, array) => {
      let newArr = [...openedPortal];
      if (element.countryName === countryName) {
        if (element.openPortal === false) {
          newArr[index] = { countryID: index, countryName, openPortal: !element.openPortal };
          newArr.forEach((innerElement, innerIndex, array) => {
            if (innerElement.countryID !== index) {
              newArr[innerIndex] = { countryID: innerIndex, countryName: innerElement.countryName, openPortal: false }
            }
          })
          setOpenedPortal(newArr); // ??
        }
        if (element.openPortal === true) {
          newArr[index] = { countryID: index, countryName, openPortal: !element.openPortal };
          setOpenedPortal(newArr); // ??
        }
      }
    })
  }

  const mobileStudyLevel = studyLevel.map((data, indexMan) => {
    const renderedStudyLevels = data.countryStudyLevels.map(({ id, level }) => {
      return (
        <HashLink key={id} smooth to={`/universities/${data.countryName}#${level}`} onClick={handleCollapse}>
          <ListItemButton sx={{ pl: 11 }}>
            <ListItemText primary={level} />
          </ListItemButton>
        </HashLink>
      )
    })

    return (
      <List component="div" disablePadding key={data.id}>
        <ListItemButton
          sx={{ pl: 4 }}
          className="collapseListContainer"
        >
          <ListItemAvatar  >
            <Avatar src={data.countryLogo} />
          </ListItemAvatar>
          <Link to={`/universities/${data.countryName}`} onClick={() => { handleCollapse() }}>Study in {data.countryName}</Link>
          {openedPortal[indexMan].openPortal ? (
            <ExpandLess
              className="collapseIcon"
              onClick={() => handleOpenStudyCountry(data.countryName, indexMan)}
            />
          ) : (
            <ExpandMore
              className="collapseIcon"
              onClick={() => handleOpenStudyCountry(data.countryName, indexMan)}
            />
          )}
        </ListItemButton>
        <Collapse in={openedPortal[indexMan].openPortal} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {renderedStudyLevels}
          </List>
        </Collapse>
      </List >
    )
  })

  const mobileService = service.map((data) => {
    return (
      <ListItemButton
        className="collapseListContainer"

      >
        <Link to={`service/${data.id}`} onClick={() => { handleCollapse() }}>{data.mainHeading}</Link>
      </ListItemButton>
    )
  })



  const smallerNav = () => {
    return (
      <>
        <Grid container justifyContent={{ xs: "space-between" }}>
          <Grid container item xs>
            <Toolbar>
              {openCollapseMenu ? (
                <ClearIcon
                  fontSize="large"
                  style={{ color: "white" }}
                  onClick={handleCollapse}
                />
              ) : (
                <MenuIcon
                  fontSize="large"
                  style={{ color: "white" }}
                  onClick={handleCollapse}
                />
              )}
            </Toolbar>
          </Grid>
          <Grid className="mobileLogoContainer" container item xs justifyContent={{ xs: "flex-end" }} alignItems={{ xs: 'center' }} style={{ paddingRight: '16px' }}>
            <Link to="/" onClick={() => setOpenCollapseMenu(false)} style={{ width: 'auto', height: '100%', padding: '8px 0px' }}>
              <img src={whiteLogo} style={{ width: '100%', height: '100%', maxWidth: '150px' }} />
            </Link>
          </Grid>
        </Grid>
        <Collapse in={openCollapseMenu}>
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "background.paper",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            style={{ padding: "0px" }}
          >
            <ListItemButton className="listItemButton" onClick={handleCollapse}>
              <Link to="/" >Home</Link>
            </ListItemButton>
            <Divider />
            <ListItemButton className="listItemButton" onClick={handleCollapse}>
              <Link to="aboutus">About Us</Link>
            </ListItemButton>
            <Divider />

            <ListItemButton
              className="listItemButton"
              onClick={handleOpenStudy}
            >
              <ListItemText primary="Study Abroad" />
              {openStudyAbroad ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openStudyAbroad} timeout="auto" unmountOnExit>
              <Divider />
              {mobileStudyLevel}
            </Collapse>

            <ListItemButton
              className="listItemButton"
              onClick={handleOpenService}
            >
              <ListItemText primary="Service" />
              {openService ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>


            <Collapse in={openService} timeout="auto" unmountOnExit>
              <Divider />
              {mobileService}
            </Collapse>


            <ListItemButton className="listItemButton" onClick={handleCollapse}>
              <Link to="/newsandevents">News & Events</Link>
            </ListItemButton>
            <Divider />
            <ListItemButton className="listItemButton" onClick={handleCollapse}>
              <Link to="/contactus">Contact</Link>
            </ListItemButton>
            <Divider />
          </List>
        </Collapse>
      </>
    );
  };

  const largerNav = () => {
    return (
      <Grid container justifyContent="space-between" alignItems="stretch" className="largerHeader" >
        <Grid container item lg={3}>
          <Link to="/">
            <img src={logo} />
          </Link>
        </Grid>
        <Grid
          container
          item
          lg={9}
          justifyContent={{ lg: "flex-end" }}
          alignItems="flex-end"
          className="navbarLinksContainer">
          <div className="headerSocialMediaContainer">
            <a href="https://www.facebook.com/northernstaredu" target="_blank" className="headerSocialAnc headerSocialAnc1">
              <i className="fab fa-facebook-f headerSocial headerSocial1"></i>
            </a>
            {/* <a href="#" className="socialMediaLink socialLinkTwitter">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="socialMediaLink socialLinkYoutube">
                <i className="fab fa-youtube"></i>
              </a> */}
            <a href="https://www.instagram.com/northernstaredunepal/" className="headerSocialAnc headerSocialAnc2" target="_blank" >
              <i className="fab fa-instagram headerSocial headerSocial2"></i>
            </a>
          </div>
          <ul className="navbarLinks">
            <li>
              <NavLink exact to="/" activeClassName="activeLink" className="navLink">
                Home
              </NavLink>
            </li>
            <li className="navLink">
              <NavLink exact to="/aboutus" activeClassName="activeLink" className="navLink">
                About Us
              </NavLink>
            </li>
            <li className="abroadStudyTrigger studyLink navLink" >
              <Link to='' className='studyLink' style={{ paddingBottom: '3px' }}>
                Study Abroad
              </Link>
              <List
                component="div"
                disablePadding
                className="abroadStudyPopper"
              >
                {renderStudyLevel}
              </List>
            </li>

            <li className="abroadStudyTrigger  studyLink navLink">
              <NavLink exact to="/ourservices" activeClassName="activeLink" className='studyLink navLink' style={{ paddingBottom: '3px' }}>
                Services
              </NavLink>
              <List component="div"
                disablePadding
                className="abroadStudyPopper"
              >
                {renderService}
              </List>
            </li>
            <li className="navLink">
              <NavLink exact to="/newsandevents" activeClassName="activeLink" className="navLink">
                News & Events
              </NavLink>
            </li>
            <li className="navLink">
              <NavLink exact to="/contactus" activeClassName="activeLink" className="navLink">
                Contact
              </NavLink>
            </li>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <FormControl className="loginPopOver">
                <TextField
                  label="Email"
                  style={{ width: '85%' }}
                  id="email"
                  variant="standard"
                  className="credentialTextField"

                />
                <TextField
                  label="Password"
                  style={{ width: '85%' }}
                  id="password"
                  variant="standard"
                  className="credentialTextField"
                />
                <Button className="credentialButton" variant="contained" style={{ color: 'white', maxWidth: '100px', marginTop: '16px' }}>Log In</Button>
              </FormControl>
            </Menu>
          </ul>
        </Grid>
      </Grid>
    );
  };


  let history = useHistory();

  if (history.location.pathname === '/admin' || history.location.pathname === '/adminpanel') {
    return null
  }
  else {
    return (
      <AppBar container position="sticky" className="header">
        {smallerDeviceMatch ? smallerNav() : largerNav()}
      </AppBar>
    );
  }
};

export default Header;

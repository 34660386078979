import { Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";


//IMAGE IMPORTS
import defaultImage from '../../assets/images/defaultImage.png';

//IMPORT BASEURL
import baseURL from '../../api/api';

//ICON IMPORTS
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from '@mui/icons-material/Cancel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';


//MODAL IMPORTS
import Modal from "@mui/material/Modal";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import Select from '@mui/material/Select';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from '@mui/material/styles';



const UniversityTable = () => {


    //ADD MODAL
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const handleOpenAddModal = async () => {
        clearStates();
        setOpenAddModal(true);
    }

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        clearStates();
    }


    //EDIT MODAL
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const handleOpenEditModal = (id) => {
        setUniversityID(id);
        setOpenEditModal(true);
        getUpdateUniversity(id);
    }
    const handleCloseEditModal = () => {
        clearStates();
        setOpenEditModal(false);
    }



    //DELETE DIALOG 
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const handleDeleteDialogOpen = (id) => {
        setUniversityID(id);
        setOpenDeleteDialog(true);
    };
    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };


    //INITIAL STATES
    const theme = useTheme();
    const [universityID, setUniversityID] = useState('');
    const [image, setImage] = useState(defaultImage);
    const [file, setFile] = useState('');
    const [uniName, setUniName] = useState('');
    const [theNames, setTheNames] = useState([]);
    const [countryName, setCountryName] = useState('');
    const [listCountries, setListCountries] = useState([]);
    const [myUniversities, setMyUniversities] = useState([]);
    const [levelName, setLevelName] = React.useState('');
    const [inputList, setInputList] = useState([{ contentType: 'para', text: "" }]);





    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => getCountries().then(() => { getStudyLevel() }), [])


    function getStyles(name, levelName, theme) {
        return {
            fontWeight:
                levelName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = (e) => {
        if (e.target.id === 'addPara') {
            setInputList([...inputList, { contentType: 'para', text: "" }]);
        }

        if (e.target.id === 'addTitle') {
            setInputList([...inputList, { contentType: 'title', text: "" }]);
        }
        if (e.target.id === 'addList') {
            setInputList([...inputList, { contentType: 'list', text: "" }]);
        }
    };

    const onImageChange = (e) => {
        setFile(e.target.files[0]);
        setImage(URL.createObjectURL(e.target.files[0]))
    };

    const onInputChange = (e) => {
        if (e.target.id === 'universityName') {
            setUniName(e.target.value);
        }
    }

    const handleChange = (event) => {
        setCountryName(event.target.value);
        studyLevel.forEach((element, index, array) => {
            if (event.target.value === element.countryName) {
                setTheNames(element.studyLevels);
            }
        })

        if (event.target.name === "studyLevel") {
            const { target: { value } } = event;
            setLevelName(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    };


    const handleTheChange = (event) => {
        setLevelName(event.target.value);
    };

    const handleTheChange1 = (e) => {
        setLevelName(e.target.value);
    }

    //UNIVERSITY DELETE
    const deleteUniversity = (id) => {
        baseURL.delete(`/institute/university/deleteById/${id}`).then(res => {
            getUniversities().then(() => {
                setOpenDeleteDialog(false);
            })
        });
    }


    // UNI MEDIA SUBMIT
    const mediaSubmit = async (e) => {
        e.preventDefault();

        let university = {
            country: countryName,
            image: '',
            name: uniName,
            studyLevel: levelName,
            universityContentHeadings: [
                {
                    heading: "Interesting Facts About Australia",
                    universityContents: inputList
                }
            ]
        }

        let data = new FormData();
        data.append('file', file);
        data.append('university', JSON.stringify(university));
        await baseURL.post('/institute/university/save', data).then(response => {
            getUniversities().then(() => {
                setOpenAddModal(false)
            })
        }).catch((err) => console.log(err, 'err err '))
    }

    // GET UNIVERSITIES
    useEffect(() => getUniversities(), []);
    const getUniversities = async () => {
        const response = await baseURL.get('/institute/university/findAll');
        setMyUniversities(response.data);
    }

    const renderedUniversitites = myUniversities.map((data, index) => {
        return (
            <tr key={data.id}>
                <td className="tableCellData">{index + 1}.</td>
                <td className="imagePreviewContainer">
                    <div className="imagePreviewBox">
                        <img src={data.image} className="portImagePreview" />
                    </div>
                </td>
                <td className="tableCellData">{data.name}</td>
                <td className="tableCellData">{data.country}</td>
                <td className="tableCellData">{data.studyLevel}</td>
                <td className="tableCellData" title={data.universityContentHeadings[0].universityContents[0].text}>
                    <span className="singleLineClamp">
                        {data.universityContentHeadings[0].universityContents[0].text}
                    </span>
                </td>
                <td>
                    <IconButton
                        color="secondary"
                        onClick={() => handleOpenEditModal(data.id)}
                    >
                        <EditIcon fontSize="medium" />
                    </IconButton>
                </td>
                <td>
                    <IconButton color="error">
                        <DeleteIcon
                            fontSize="medium"
                            onClick={() => handleDeleteDialogOpen(data.id)}
                        />
                    </IconButton>
                </td>
            </tr>
        )
    })


    //GET COUNTRY  BY ID
    const getUpdateUniversity = async (id) => {
        await baseURL.get(`/institute/university/findById/${id}`).then((res) => {
            setImage(res.data.image);
            setCountryName(res.data.country);
            setUniName(res.data.name);

            studyLevel.forEach((element, index, array) => {
                if (res.data.country === element.countryName) {
                    setTheNames(element.studyLevels);
                }
            })

            setLevelName(res.data.studyLevel);
            const updateContents = res.data.universityContentHeadings[0].universityContents.map(({ text, contentType }) => {
                return { text, contentType };
            });
            setInputList(updateContents)
        })
    }

    // UPDATE UNIVERSITY
    const mediaUpdate = async (e) => {
        e.preventDefault();
        let university = {
            country: countryName,
            image: '',
            name: uniName,
            studyLevel: levelName,
            universityContentHeadings: [
                {
                    heading: "Interesting Facts About Australia",
                    universityContents: inputList
                }
            ]
        }

        let data = new FormData();
        data.append('file', file);
        data.append('university', JSON.stringify(university));
        await baseURL.put(`/institute/university/update/${universityID}`, data).then(response => {
            getUniversities().then(() => {
                setOpenEditModal(false)
            })
        }).catch((err) => console.log(err, 'err err '))

    }

    // GET COUNTRIES
    const getCountries = async () => {
        await baseURL.get('/institute/country/findAll').then(({ data }) => {
            data.map((data) => {
                setListCountries(prevArray => [...prevArray, data.countryName]);
            })
        })
    }


    //GET STUDY LEVEL
    const [studyLevel, setStudyLevel] = useState([]);
    const getStudyLevel = async () => {
        const response = await baseURL.get('/institute/country/studyLevel/findAll').then((res) => {
            res.data.map(({ countryName, countryStudyLevels }) => {
                const renderedCountryStudyLevels = countryStudyLevels.map(({ level }) => {
                    return level;
                })
                setStudyLevel(studyLevel => [...studyLevel, { countryName: countryName, studyLevels: renderedCountryStudyLevels }])
            })
        })
    }


    //CLEAR STATES
    const clearStates = () => {
        setImage(defaultImage);
        setUniversityID('');
        setFile('');
        setUniName('');
        setTheNames([]);
        setCountryName('');
        setLevelName('');
        setInputList([{ contentType: 'para', text: "" }])
    }

    return (
        <>
            <div className="tabPanelHeader">
                <h3>Universities Table</h3>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpenAddModal()}
                    className="addButton"
                >
                    Add
                </Button>
            </div>
            <div>
                <table>
                    <thead className="serviceTableHead">
                        <th>SN</th>
                        <th>University Image</th>
                        <th>University Name</th>
                        <th>Country</th>
                        <th>Study Level</th>
                        <th>University Description</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </thead>
                    <tbody>
                        {renderedUniversitites}
                    </tbody>
                </table>


                <Modal
                    open={openAddModal}
                    onClose={handleCloseAddModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="updateModal"
                >
                    <div className="addNewTeamModalPopUp">
                        <div className="modalHeader">
                            <h3>Add University</h3>
                        </div>
                        <div className="modalContainer">
                            <FormControl className="modalContent" component="form" onSubmit={(e) => mediaSubmit(e)}>
                                <div className="updateImagePreview">
                                    <img src={image} />
                                </div>
                                <div className="textFieldContainer">
                                    <TextField
                                        type="file"
                                        onChange={onImageChange}
                                        variant="standard"
                                        className="textField"
                                        required
                                    />

                                    <TextField
                                        label="University Name"
                                        style={{ width: '100%' }}
                                        onChange={onInputChange}
                                        id="universityName"
                                        value={uniName}
                                        variant="standard"
                                        className="textField"
                                        required
                                    />

                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                        <InputLabel id="countryLabel">Country</InputLabel>
                                        <Select
                                            labelId="countryLabel"
                                            id="countryLabelID"
                                            value={countryName}
                                            onChange={handleChange}
                                            label="Country"
                                            required
                                        >
                                            {listCountries.map((name) => (
                                                <MenuItem
                                                    key={`${name}`}
                                                    value={`${name}`}
                                                    style={getStyles(name, levelName, theme)}
                                                >
                                                    {`${name}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-standard-label">Study Level</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            // value={levelName}
                                            onChange={handleTheChange}
                                            label="Study Level"
                                            required
                                        >
                                            {theNames.map((name) => (
                                                <MenuItem
                                                    key={`${name}`}
                                                    value={`${name}`}
                                                    style={getStyles(name, levelName, theme)}
                                                >
                                                    {`${name}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>


                                    {inputList.map((x, i) => {
                                        if (x.contentType === 'para') {
                                            return (
                                                <div className="box" key={i}>
                                                    <TextField
                                                        label="University Paragraph"
                                                        name="text"
                                                        style={{ width: '100%' }}
                                                        value={x.text}
                                                        onChange={e => handleInputChange(e, i)}
                                                        multiline
                                                        rows={4}
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&

                                                            <IconButton color="error">
                                                                <CancelIcon
                                                                    fontSize="medium"
                                                                    onClick={() => handleRemoveClick(i)}
                                                                />
                                                            </IconButton>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        }
                                        if (x.contentType === 'title') {
                                            return (
                                                <div className="box" key={i}>
                                                    <TextField
                                                        label="Sub Title"
                                                        name='text'
                                                        style={{ width: '100%' }}
                                                        value={x.text}
                                                        onChange={e => handleInputChange(e, i)}
                                                        variant="standard"
                                                        className="textField"
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&

                                                            <IconButton color="error">
                                                                <CancelIcon
                                                                    fontSize="medium"
                                                                    onClick={() => handleRemoveClick(i)}
                                                                />
                                                            </IconButton>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        }
                                        if (x.contentType === 'list') {
                                            return (
                                                <div className="box" key={i}>
                                                    <TextField
                                                        label="List"
                                                        name='text'
                                                        style={{ width: '100%' }}
                                                        value={x.text}
                                                        onChange={e => handleInputChange(e, i)}
                                                        variant="standard"
                                                        className="textField"
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&

                                                            <IconButton color="error">
                                                                <CancelIcon
                                                                    fontSize="medium"
                                                                    onClick={() => handleRemoveClick(i)}
                                                                />
                                                            </IconButton>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>

                                <div className="submitContainer">
                                    <Button variant="contained" color="error" onClick={() => clearStates()} >
                                        CLEAR
                                    </Button>
                                    <Button variant="contained" type="submit">SUBMIT</Button>
                                </div>
                            </FormControl>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={openEditModal}
                    onClose={handleCloseEditModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="updateModal"
                >
                    <div className="addNewTeamModalPopUp">
                        <div className="modalHeader">
                            <h3>Update University</h3>
                        </div>
                        <div className="modalContainer">
                            <FormControl className="modalContent" component="form" onSubmit={(e) => mediaUpdate(e)}>

                                <div className="updateImagePreview">
                                    <img src={image} />
                                </div>
                                <div className="textFieldContainer">
                                    <TextField
                                        type="file"
                                        onChange={onImageChange}
                                        variant="standard"
                                        className="textField"
                                        id="countryImage"
                                        label="Update University Image"
                                        InputLabelProps={{ shrink: true }}
                                    />

                                    <TextField
                                        label="University Name"
                                        style={{ width: '100%' }}
                                        onChange={onInputChange}
                                        id="universityName"
                                        value={uniName}
                                        variant="standard"
                                        className="textField"
                                        required
                                    />

                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                        <InputLabel id="countryLabel">Country</InputLabel>
                                        <Select
                                            labelId="countryLabel"
                                            id="countryLabelID"
                                            value={countryName}
                                            onChange={handleChange}
                                            label="Country"
                                        >
                                            {listCountries.map((name) => (
                                                <MenuItem
                                                    key={`${name}`}
                                                    value={`${name}`}
                                                    style={getStyles(name, levelName, theme)}
                                                >
                                                    {`${name}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-standard-label">Update Study Level</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={levelName}
                                            onChange={handleTheChange1}
                                            label="Study Level"
                                            required

                                        >
                                            {theNames.map((name) => (
                                                <MenuItem
                                                    key={`${name}`}
                                                    value={`${name}`}
                                                    style={getStyles(name, levelName, theme)}
                                                >
                                                    {`${name}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {inputList.map((x, i) => {
                                        if (x.contentType === 'para') {
                                            return (
                                                <div className="box">
                                                    <TextField
                                                        label="Paragraph"
                                                        name="text"
                                                        style={{ width: '100%' }}
                                                        value={x.text}
                                                        onChange={e => handleInputChange(e, i)}
                                                        multiline
                                                        rows={4}
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&
                                                            <>
                                                                <IconButton color="error" onClick={() => handleRemoveClick(i)}
                                                                >
                                                                    <CancelIcon
                                                                        fontSize="medium"
                                                                    />
                                                                </IconButton>
                                                            </>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div className="CMSkeys">
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );

                                        }
                                        if (x.contentType === 'title') {
                                            return (
                                                <div className="box">

                                                    <TextField
                                                        label="Secondary Title"
                                                        style={{ width: '100%' }}
                                                        onChange={e => handleInputChange(e, i)}

                                                        value={x.text}
                                                        name="text"
                                                        variant="standard"
                                                        className="textField"
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&
                                                            <IconButton color="error">
                                                                <CancelIcon
                                                                    fontSize="medium"
                                                                    onClick={() => handleRemoveClick(i)}
                                                                />
                                                            </IconButton>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div className="CMSkeys">
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );

                                        }
                                        if (x.contentType === 'list') {
                                            return (
                                                <div className="box">

                                                    <TextField
                                                        label="List"
                                                        style={{ width: '100%' }}
                                                        onChange={e => handleInputChange(e, i)}

                                                        name="text"
                                                        value={x.text}
                                                        variant="standard"
                                                        className="textField"
                                                    />

                                                    <div className="btn-box">
                                                        {inputList.length !== 1 &&
                                                            <IconButton color="error">
                                                                <CancelIcon
                                                                    fontSize="medium"
                                                                    onClick={() => handleRemoveClick(i)}
                                                                />
                                                            </IconButton>
                                                        }
                                                        {inputList.length - 1 === i &&
                                                            <>
                                                                <div className="CMSkeys">
                                                                    <Button id="addTitle" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Title</Button>
                                                                    <Button id="addPara" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add Para</Button>
                                                                    <Button id="addList" size="small" variant="outlined" onClick={(e) => handleAddClick(e, i)}>Add List</Button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                                <div className="submitContainer">
                                    <Button variant="contained" color="error" onClick={() => clearStates()} >
                                        CLEAR
                                    </Button>
                                    <Button variant="contained" type="submit" >UPDATE</Button>
                                </div>
                            </FormControl>
                        </div>

                    </div>
                </Modal >

                <Dialog
                    open={openDeleteDialog}
                    onClose={handleDeleteDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Are you sure want to delete the selected data?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            onClick={handleDeleteDialogClose}
                            variant="contained"
                            color="primary"
                        >
                            NO
                        </Button>
                        <Button
                            onClick={() => { deleteUniversity(universityID) }}
                            variant="contained"
                            color="error"
                            autoFocus
                        >
                            YES
                        </Button>
                    </DialogActions>
                </Dialog>

            </div >
        </>
    )
}

export default UniversityTable;

import { Button, Divider } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Slider from "react-slick";
import girl from "../../assets/images/girl.png";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";


import Events from "../common/innerComponents/Events";
import React, { useState, useEffect } from "react";

//IMPORT BASEURL
import baseURL from '../../api/api';


const TestimonyAndEvents = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
  };


  //GET EVENTS DATA
  const [myEvents, setMyEvents] = useState([]);
  useEffect(() => getEvents(), []);

  const getEvents = async () => {
    const response = await baseURL.get('/institute/home/events/findAll');
    setMyEvents(response.data);
  }

  const renderedEvents = myEvents.slice(0, 3).map(({ id, date, location, time, title }, index) => {
    const theDate = new Date(date);
    // const ISOToString = theDate.toISOString().substring(0, 10); //(2020-10-22)
    // console.log(ISOToString,'my dear')

    let theMonth = theDate.toLocaleString('en-us', { month: 'short' });
    let theDay = theDate.getDate();

    let theHour = time.substring(0, 2);
    let theMinute = time.substring(3, 5);
    const formatAMPM = () => {
      var ampm = theHour >= 12 ? 'PM' : 'AM';
      theHour = theHour % 12;
      theHour = theHour ? theHour : 12; // the hour '0' should be '12'
      theHour = theHour < 10 ? `0${theHour}` : theHour;
      theMinute = theMinute < 10 ? '00' + theMinute : theMinute;
      var strTime = theHour + ':' + theMinute + ' ' + ampm;
      return strTime;
    }


    return (
      <Link to="/aboutus" className="eventsCardLink" key={id}>
        <div className="eventsCard">
          <div className="eventsDate">
            <div className="eventsDay">
              <h1>{theDay}</h1>
            </div>
            <div className="eventsMonth">
              <h3>{theMonth}</h3>
            </div>
          </div>
          <div className="eventsDetail">
            <div className="eventsName">
              <h1>{title}</h1>
            </div>
            <div className="eventsTimingVenue">
              <div className="eventsMetaData">
                <div className="eventsIcon">
                  <AccessTimeIcon fontSize="small" />
                </div>
                <div className="eventsTime">
                  <p>{formatAMPM()}</p>
                </div>
              </div>
              <div className="eventsMetaData">
                <div className="eventsIcon">
                  <LocationOnIcon fontSize="small" />
                </div>
                <div className="eventsTime">
                  <p>{location} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider className="eventCardDivider1" />
      </Link>
    )


  })

  //GET TESTIMONIALS DATA
  const [myTestimony, setMyTestimony] = useState([]);
  useEffect(() => getTestimony(), []);

  const getTestimony = async () => {
    const response = await baseURL.get('/institute/home/testimonial/findAll');
    setMyTestimony(response.data);
  }

  const renderedTestimony = myTestimony.map(({ id, name, image, country, content }, index) => {
    return (
      <div className="testimonialsCard" key={id}>
        <div className="testimonialsProfile">
          <div className="testimonialsProfileImage">
            <img src={image} />
          </div>
          <div className="testimonialsProfileDetails">
            <h1>{name}</h1>
            <h3>{country}</h3>
          </div>
        </div>
        <div className="testimonialsQuoteCard">
          <p className="testimonialsQuotes para-clamping">
            {content}
          </p>
        </div>
      </div>

    )
  })


  //GET NEWS
  //GET NEWS DATA
  const [news, setNews] = useState([]);
  useEffect(() => getNews(), []);
  const getNews = async () => {
    const response = await baseURL.get('/institute/home/news/findAll');
    setNews(response.data.reverse());
  }

  const renderedNews = news.slice(0, 4).map(({ id, image, date, newsContentHeadings }, index) => {
    const theDate = new Date(date);
    let theMonth = theDate.toLocaleString('en-us', { month: 'long' });
    let theWeekDay = theDate.toLocaleDateString('en-US', { weekday: 'long' })
    let theDay = theDate.getDate();
    let theYear = theDate.getFullYear();

    const renderedNewsContentHeadings = newsContentHeadings.map(({ id, contentType, heading, newsContents }) => {
      const renderedNewsContents = newsContents.map(({ text }) => {
        return text;
      })
      return [heading, renderedNewsContents];
    });

    const renderedNews = renderedNewsContentHeadings[0][1].map((data, index) => {
      return (
        <span key={`${data}${index}`}>{data}</span>
      )
    })

    return (
      <Grid container item xs="auto" key={id} className="newsCard" >
        <Grid container item xs={12}>
          <img src={image} style={{ width: "100%" }} />
        </Grid>
        <Grid container item className="newsCardDescription">
          {/* <Grid container item>
            <p>{theWeekDay}, {theMonth} {theDay}, {theYear}</p>
          </Grid> */}
          <Grid container item>
            <h5>
              {renderedNewsContentHeadings[0][0]}
            </h5>
          </Grid>
          <Grid container item >
            <p className="newsContainer">
              {renderedNews}
            </p>
          </Grid>
          <Grid container item className="readMoreHolder">
            <Link to={`/news/${id}`} className="getMoreDetailsBtn">
              Read More
            </Link>
          </Grid>
        </Grid>
      </Grid>
    )

  })






  return (
    <div>
      <div className="eventsAndNewsTitleHolder">
        <h5>EVENTS AND NEWS</h5>
      </div>
      <div className="testimonyAndEvents">
        <div className="events" >
          <div className="eventsContainer">
            {renderedEvents}
          </div>
          <div className="eventsSeeMore">
            <Link to="/newsandevents" className="navigateLink">See More &gt;&gt;</Link>
          </div>
        </div>

        <div className="testimonials">
          {/* <div className="testimonialsSlidesHolder">
          <Slider {...settings}>
            {renderedTestimony}
          </Slider>
          <i className="fas fa-quote-left quoteIcon"></i>
        </div> */}
          <div className="testimonialReadMoreContainer">
            <Link to="/newsandevents" className="testimonyRead navigateLink">READ ALL NEWS &gt;&gt;</Link>
          </div>
          <div className="testimonialsContainer">
            {renderedNews}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonyAndEvents;

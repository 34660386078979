import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import * as React from "react";
import Slide1 from "../../assets/images/slide1.png";
import Slide2 from "../../assets/images/slide2.png";
import MOE from "../../assets/images/MOE.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';



//IMPORT BASEURL
import baseURL from '../../api/api';




const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));



const NewsAccreditations = () => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  //GET NEWS DATA
  const [news, setNews] = useState([]);
  useEffect(() => getNews(), []);
  const getNews = async () => {
    const response = await baseURL.get('/institute/home/news/findAll');
    setNews(response.data.reverse());
  }

  const renderedNews = news.slice(0, 2).map(({ id, image, date, newsContentHeadings }, index) => {
    const theDate = new Date(date);
    let theMonth = theDate.toLocaleString('en-us', { month: 'long' });
    let theWeekDay = theDate.toLocaleDateString('en-US', { weekday: 'long' })
    let theDay = theDate.getDate();
    let theYear = theDate.getFullYear();

    const renderedNewsContentHeadings = newsContentHeadings.map(({ id, contentType, heading, newsContents }) => {
      const renderedNewsContents = newsContents.map(({ text }) => {
        return text;
      })
      return [heading, renderedNewsContents];
    });

    const renderedNews = renderedNewsContentHeadings[0][1].map((data, index) => {
      return (
        <span key={`${data}${index}`}>{data}</span>
      )
    })

    return (
      <Grid container item xs="auto" md={5} key={id} className="newsCard">
        <Grid container item xs={12}>
          <img src={image} style={{ width: "100%" }} />
        </Grid>
        <Grid container item className="newsCardDescription">
          <Grid container item>
            <p>{theWeekDay}, {theMonth} {theDay}, {theYear}</p>
          </Grid>
          <Grid container item>
            <h3>
              {renderedNewsContentHeadings[0][0]}
            </h3>
          </Grid>
          <Grid container item >
            <p className="newsContainer">
              {renderedNews}
            </p>
          </Grid>
          <Grid container item>
            <Link to={`/news/${id}`}>
              <Button size="small">Read More</Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    )

  })




  //GET ACCREDITATIONS DATA
  const [accreditations, setAccreditations] = useState([]);
  useEffect(() => getAccreditations(), []);
  const getAccreditations = async () => {
    const response = await baseURL.get('/institute/home/accreditation/findAll');
    setAccreditations(response.data.reverse())
  }
  
  const renderedAccreditations = accreditations.map(({ id, fileName, image }, index) => {
    return (
      <Accordion
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
        style={{ width: '100%' }}
        key={id}
      >
        <AccordionSummary
          aria-controls={`panel${index}d-content`}
          id={`panel${index}d-header`}
          className="accreditationsSummary"
        >
          <Typography>{fileName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography> */}
          <img src={image} className="accreditationsImage" style={{ width: "100px" }} />

        </AccordionDetails>
      </Accordion>


    )
  });


  return (
    <Grid
      container
      direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
      columnSpacing={{ lg: 5 }}
      className="newsAccreditations"
    >
      <Grid container item xs="auto" sm="auto" md="column" lg={7}>
        <Grid
          container
          item
          direction={{ xs: "row" }}
          justifyContent={{ xs: "space-between" }}
          alignItems={{ xs: "center" }}
        >
          <Grid container item xs={6}>
            <h1>NEWS</h1>
          </Grid>
          <Grid container item xs={6} justifyContent={{ xs: "flex-end" }}>
            <Link to="/newsandevents">View All News</Link>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction={{ xs: "column", md: "row" }}
          justifyContent={{ md: "flex-start" }}
          className="newsCardHolder"
        >
          {renderedNews}
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs="auto"
        sm="auto"
        md="column"
        lg={5}
        direction={{ xs: "column" }}
      >
        <Grid container item xs="auto"
        >
          <h1>ACCREDITATIONS</h1>
        </Grid>
        <Grid container item xs="auto"
        >
          {renderedAccreditations}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewsAccreditations;
